@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0 4rem 0;
}

.imageContainer{
    margin: 2rem 0 1.2rem 0;
}

.headerText{
    @include primaryP();
    color: $dark3;
    padding-bottom: 1rem;
    text-align: center;
}

.nameText{
    @include primaryP();
    font-size: 20px;
}

.image {
    width: 110px;
    height: 110px;
    border-radius: 100%;
}