@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    width: 100%;
    min-height: 500px;
    display: flex;
    z-index: 1;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.contentContainer {
    z-index: 2;
    justify-content: center;
    align-items: center;
}

.headingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
}

.headingTextContainer {
    max-width: 400px;

    @media #{$mobile-only} {
        max-width: 100%;
    }
}

.searchContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.searchBoxContainer {
    display: flex;
    background-color: $light0;
    justify-content: space-between;
    flex-direction: row;
    margin-right: 2rem;
    width: 340px;
    height: 36px;

    @media #{$mobile-only} {
        width: 220px;
        height: 36px;
    }
}

.buttonContainer {
    @include primaryP();
    display: flex;
    justify-content: center;
    align-items: center;
    color: $light0;
    font-size: 14px;
    background-color: $primary;
    width: 120px;
    height: 36px;
    border-radius: 6px;

    @media #{$mobile-only} {
        width: 60px;
        height: 36px;
        font-size: 10px;
    }

    @media #{$tablet-portrait-up} {
        width: 100px;
        height: 36px;
    }

    @media #{$tablet-landscape-up} {
        width: 100px;
        height: 36px;
    }
}

.headingText {
    @include primaryH1();
    color: $light0;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
}

.inputBigSearch {
    @include primaryP();
    font-size: 10px;
    display: block;
    box-sizing: border-box;
    padding: 1rem 1rem;
    height: 36px;
    width: 300px;
    background-color: transparent;

    @media #{$mobile-only} {
        width: 200px;
        height: 36px;
    }
}

.searchIcon {
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 15px;
    color: $dark3;
    margin-right: 0.8rem;
}

.searchText {
    @include primaryP();
    color: $dark3;
    font-size: 10px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-self: center;
    padding: 0rem 1rem;
    width: 100%;
}
