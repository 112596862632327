@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {}

.imageContainer {
    background-color: $dark0;
}

.contentContainer {
    flex-direction: row;
    display: flex;
    flex-wrap: 1;
}

.logoContainer {
    width: 22rem;

    @media #{$mobile-only} {
        width: 22rem;
    }
}

.textContainer {
    margin-left: 2rem;
    display: flex;
    flex-direction: row;
    flex-grow: 4;

    @media #{$mobile-only} {
        flex-direction: column;
    }
}

.buttonContainer {
    justify-items: center;
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
}

.button {
    width: 100%;
    border-radius: 5px;
    padding: 1rem 3rem;
    margin-left: 2rem;

    @media #{$mobile-only} {
        margin-left: 0;
        margin-top: 1rem;
    }
}

.textSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;

    @media #{$mobile-only} {
        width: 100%;
    }
}

.image {
    width: 100%;
    height: 36rem;
    object-fit: cover;

    @media #{$mobile-only} {
        height: 26rem;
    }
}

.logo {
    width: 90%;
    height: auto;
}

.titleText {
    @include secondaryH1();
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 4.9rem;
    color: $dark1;
}

.descriptionText {
    @include primaryP();
    margin-top: 1.6rem;
    line-height: 2.4rem;
    color: $dark1;
    max-width: 70rem;
}