@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.box {
    background: $panelBg;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.textWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

.followButton {
    @include primaryP();
    letter-spacing: 0;
    font-size: 13px;
    color: $light0;
    height: auto;
    padding: 4px 16px;
    border-radius: 20px;
    margin-bottom: 4px;
}

.followed {
    background: $secondary;
}

.image {
    width: 100%;
    max-width: 160px;
    height: auto;
}

.title {
    @include secondaryH2();
}

.content {
    @include primaryP();

}

@media #{$tablet-landscape-up} {

    .title {
        @include secondaryH1();
    }

    .content {
        font-size: 16px;
        line-height: 24px;
    }

}