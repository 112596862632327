@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container{}

.coverBanner {
    z-index: 1;
}

.content {
    z-index: 2;
    margin-top: -120px;
}
