@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.textContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
.textRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 2.5rem;
  align-items: center;
}

.strongText {
  @include primaryP();
  font-size: 1.4rem;
  color: $dark1;
  font-weight: 600;
  margin-right: 0.5rem
}

.text {
  @include primaryP();
  font-size: 1.4rem;
  color: $dark1;
  font-weight: 300;
}

.textWeb {
  @include primaryP();
  font-size: 1.2rem;
  color: $dark1;
  font-weight: 300;
}
