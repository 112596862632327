@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.searchBoxContainer {
    display: flex;
    background-color: $light0;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex: 1;
}

.searchField {
    @include secondaryH2();
    box-sizing: border-box;
    display: block;
    height: 8rem;
    width: 100%;
    background-color: transparent;
}

.searchIconContainer {
    padding-right: 2rem;
    cursor: pointer;
}

.searchIcon {
    font-size: 2rem;
    color: $primary;
    display: block;
}

.types {
    background-color: $light3;
    display: flex;
    justify-content: space-between;
}

.type {
    padding: 1.5rem 1rem;
    text-align: center;
    @include secondaryH4();
    color: $dark3;
    width: 100%;
    cursor: pointer;
}

.type:hover {
    background: $primaryLight;
    color: $primary;
}

.typeActive {
    background-color: $primary;
    color: $light0;
}

.select :global {

    .form-field-select__control {
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid $light3;
        background: $light0;
        height: 48px;
        box-shadow: none;
        @include primaryP();
    }

    .form-field-select__control:hover,
    .form-field-select__control--menu-is-open,
    .form-field-select__control--is-focused {
        border-bottom-color: $primary;
    }

    .form-field-select__menu {
        @include secondaryH4();
        border-radius: 0;
        margin-top: 0;
        background-color: $dark1;
        color: $light0;
    }


    .form-field-select__option:active {
        color: $primary;
    }

    .form-field-select__option--is-focused {
        background: $dark0;
        color: $primary;
        cursor: pointer;
    }

    .form-field-select__option--is-selected {
        background: $primary;
        color: $light0;
    }

}