@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    width: 340px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    box-sizing: border-box;
    min-height: 220px;
    border-radius: 10px;
    padding: 1.5rem;
    overflow: hidden;
}

.image {
    max-width: 100%;
    width: 100%;
    height: 220px;
    background: $light3;
    border-radius: 8px;
    margin-bottom: 16px;
}

.title {
    @include secondaryH2();
    color: $light0;
    z-index: 1;
}

.description {
    @include secondaryP();
    color: $light0;
    mix-blend-mode: normal;
    z-index: 1;
}