@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.card {}

.cardHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.headContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerLogo {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 4px;
}

.headerText {
    @include secondaryH5();
    color: $dark1;
}

.image {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    border-radius: 8px;
    background: $light3;
}

.title {
    @include secondaryH3();
    margin-bottom: 10px;
}

.content {
    @include primaryP();
}

.timeText {
    @include primaryP();
    color: $secondary;
    font-size: 12px;
}

.sportTag {
    display: inline-flex;
    background-color: $alt;
    margin: 1.5rem 0;
    padding: 0.5rem 1.5rem;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    @include primaryP();
    color: $light0;
    font-weight: bold;
    font-size: 12px;
}