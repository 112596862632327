@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.itemContainer{
    padding: 1rem !important;
}
.container{
    padding: 20px 0;
}
.reviewContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.starFill{
    color: $primary;
    font-size: 22px;

    
    @media #{$mobile-only} {
        font-size: 20px;
    }

    @media #{$tablet-portrait-up} {
        font-size: 19px;
    }

    @media #{$tablet-landscape-up} {
        font-size: 18px;
    }
}

.starEmpty{
    color: $light3;
    font-size: 22px;
}

.reviewText{
    @include primaryP();
    font-weight: 500;
    font-size: 19px;
    margin-left: 1rem;

    @media #{$mobile-only} {
        font-size: 18px;
    }

    @media #{$tablet-portrait-up} {
        font-size: 16px;
    }

    @media #{$tablet-landscape-up} {
        font-size: 15px;
    }
}

.reviews{
    display: flex;
    align-items: center;
}

.reviewText{
    margin-right: 26px;
}

.reviewButton{
    @include secondaryH4();
    color: $light0;
    height: auto;
    padding: 8px 16px;
    
}

.submitReview{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.submitReviewMessage{
    @include secondaryH2();
    font-weight: 600;
    margin-bottom: 15px;
}


.popup {
    position: relative;
    background: $panelBg;
}

.close {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2.2rem;
}

.startEmpty{
    color: $light3;
}

.StarFill {
    color:$primary;
}