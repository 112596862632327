@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.profileContainer {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
}

.leftContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 1rem;
    background-color: $light0;

    @media #{$tablet-portrait-down} {
        display: none;
    }

}

.rightContainer {
    flex: 2;
    background-color: $light0;
    padding: 4rem;
    width: 100%;
    overflow: hidden;

    @media #{$mobile-only} {
        padding: 3rem;
    }

}

.edge {
    margin: -2rem -4rem;
}