@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.box {
    background: $panelBg;
}

.popup {
    position: relative;
    background: $panelBg;
}

.close {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2.2rem;
}

.headingContainer {
    padding: 1rem 0 2rem 0;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0 2rem 0;
}

.headerAlt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0 4rem 0;
}

.sessionCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 4rem 4rem 4rem;
}

.footerContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.footerContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 1.2rem;
}

.buttonContainer {
    padding-bottom: 4rem;
    width: 35rem;
}

.colContainer {
    padding-bottom: 2rem;
}


.text {
    @include primaryP();
    color: $dark3;
}

.headerText {
    @include secondaryH3();
    padding-bottom: 1rem;
}

.stepHeading {
    @include primaryP();
    font-weight: 600;
    color: $dark1;
}

.stepText {
    @include primaryP();
    color: $dark3;
}

.stepLink {
    color: $blueHl;
}

.buttons {
    display: flex;
    gap: 1rem;
}

.subHeading {
    @include secondaryH3();
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 1rem;
}

.btnIcon {
    color: $primary !important;
}

.attendeeRemove {
    cursor: pointer;
}

.popupContent{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.confirmText{
    @include primaryP();
    color: $dark3;
    font-weight: 400;
    margin-bottom: 1rem;
}