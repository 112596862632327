@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {}

.dateWrapper {
    width: 6.4rem;
    height: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $light2;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: $light3;
    }
}

.selected {
    background-color: $primary;

    &:hover {
        background-color: $primary;
    }
}

.text {
    @include secondaryH4();
    font-size: 1.6rem;
    font-weight: 500;
}

.textSelected {
    color: $light0
}

.flexColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1rem;
}

.dateInfo {
    @include secondaryP();
}