@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.form {
    padding: 36px 30px;
    background: $light0;

    & fieldset {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

.title {
    @include secondaryH3();
    font-weight: 600;
    margin-bottom: 20px;
}

.quation {
    @include secondaryP();
    margin-bottom: 20px;
}

.empty {
    color: $light3;
}
.full {
    color: $primary;
}
.message {
    height: 420px;
}
.buttonSubmit {
    @include primaryH4();
    font-weight: 300;
    height: auto;
    padding: 14px 0;
    color: $light0;
    margin-top: 14px;
}
