@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.itemSymbol {
    display: inline-block;
    width: 50px;
    height: 26px;
    border-radius: 26px;
    border: 2px solid $secondary;
    cursor: pointer;
    transition: .3s ease all;
    position: relative;
    box-sizing: border-box;
}

.itemSymbol:after,
.itemSymbolAfter {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 18px;
    display: inline-block;
    background: $secondary;
    transition: .3s ease all;
    position: absolute;
    top: 2px;
    left: 2px;
}

.itemSymbolChecked {
    @extend .itemSymbol;
    border-color: $primary;
}

.itemSymbolChecked:after {
    @extend .itemSymbolAfter;
    background: $primary;
    left: 25px;
}