@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.table {
    width: 100%;
}

.table :global {

    .sticky-table-table {
        width: 100%;
    }
    
    .sticky-table-row {
        vertical-align: middle;
    }

    .head .sticky-table-cell {
        text-transform: uppercase;
        background-color: $dark3;
        color: $light2;
    }

    .sticky-table-cell {
        padding: 2rem 1rem;
        border: 0px;
        border-bottom: 1px solid $light3 !important;
        border-radius: 0 !important;
        white-space: nowrap;
        @include secondaryH4();
        width: 80px;
        text-align: center;
        color: $dark1;
    }

    .sticky-table-cell a {
        color: currentColor;
    }

    .sticky-table-cell.date {
        padding-left: 2rem;
        width: 150px;
        text-align: left;
    }

    .sticky-table-cell.team {
        width: 200px;
        text-align: left;
    }

    .item {
        display: flex;
        align-items: center;
    }

}