@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    background: $secondary;
    padding: .5rem 1.5rem;
    border-radius: 5rem;

    &:hover {
        background: $primary;
    }
}

.text {
    @include secondaryH5();
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0;
    color: $light0;
}