@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $light3 !important;
    padding: 0.2rem 0 1rem 0;
}

.logoContainer {
    margin-right: 1rem;
}

.contentContainer {
    display: flex;
    flex: 1;
    align-items: center;
}

.contentContainerInner {
    flex: 1;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.heading {
    @include secondaryH4();
}

.description {
    @include secondaryP();
    color: $dark3;
    height: 1.5rem;
    @include ellipsis(80%);
    white-space: normal;

    @media #{$mobile-only} {
        height: 2rem;
    }
}

.icon {
    font-size: 26px;
    color: $dark0;
}