@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.panel {
    flex-shrink: 0;
    width: calc(50% - 2rem);
    margin-bottom: 4rem;

    @media #{$tablet-landscape-down} {
        width: 100%;
        margin-bottom: 3rem;
    }
}

.title {
    @include secondaryH2();
    font-size: 2.4rem;
    font-weight: 600;
    margin-right: 1rem;
}

.blockBookingLink {
    @include primaryP();
    font-size: 1.3rem;
    color: $primary;
    cursor: pointer;
}

.slots {
    display: flex;
    flex-wrap: wrap;
    gap: .8rem;
}

.slot {
    @include secondaryP();
    color: $dark3;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: $light2;
    border-radius: 5px;
    flex-basis: 10rem;
    line-height: 3.5rem;
    flex-grow: 1;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: $light3;
    }
}

.slotsDisabled {
    @extend .slot;
    opacity: .5;

    &:hover {
        @extend .slot;
        cursor: not-allowed;
    }
}

.slotSelected {
    background-color: $primary;
    color: $light0;

    &:hover {
        background-color: $primary;
    }
}

.info {
    @include secondaryP();
    margin-top: 2rem;
}

.titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: space-between;
}