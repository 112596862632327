@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    position: relative;
    z-index: 5;
}

.bar {
    position: absolute;
    height: .6rem;
    width: 100%;
    background: $light3;
    border-radius: 20px;
    top: 5.6rem;
    z-index: 1;
}

.progress {
    height: .6rem;
    background: $primary;
    border-radius: 20px;
    position: relative;
}

.scoreWrap {
    position: absolute;
    height: .6rem;
    top: 5.6rem;
    z-index: 4;
}

.score {
    @include secondaryP();
    position: absolute;
    background: $primary;
    padding: 0 0 2px 0;
    font-weight: bold;
    color: $light0;
    width: 3rem;
    text-align: center;
    right: 0px;
    transform: translate(50%, 2rem);
    border-radius: 0 0 2px 2px;
}

.score:before {
    content: "";
    border-style: solid;
    border-width: 0 1.5rem .8rem 1.5rem;
    border-color: transparent transparent $primary transparent;
    position: absolute;
    bottom: 100%;
    left: 0;
}

.items {
    display: flex;
    justify-content: space-evenly;
    position: relative;
}

.item {
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space: pre;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 2;
}

.number {
    height: 4rem;
    color: $light6;
}

.label {
    @include primaryP();
    color: $dark3;
}

.dot {
    position: relative;
    height: 6rem;
}

.dot:after {
    content: "";
    display: inline-block;
    border: 2px solid $light3;
    background: $light0;
    margin-top: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 3rem;
    position: relative;
    z-index: 1;
}

.dot:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 6rem;
    background-color: $light3;
    z-index: 0;
    top: -1rem;
    left: 50%;
}

.passed .dot:after {
    border-color: $primary;
}