@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.indicator {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 1.8rem;
    color: rgba($dark3, 0.5);
}

.wrap {
    background-color: $light2;
    padding: 0rem 1rem;
    width: 13rem;
    height: 38px;
    border-radius: 0.5rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
}

.wrap :global {
    input {
        @include secondaryH4();
        font-size: 1.6rem;
        line-height: 1.6rem;
        color: $dark3;
        display: block;
        width: 100%;
    }

    .react-datepicker__close-icon {
        right: 1.6rem;

        &:after {
            color: rgba($dark3, 0.5);
        }

        &:hover:after {
            color: $dark3;
        }
    }

    .react-datepicker{
        width: 100%;
        padding: 0 0.8rem;
        box-sizing: border-box;
    }
    
    .react-datepicker__month-container{
        width: 25rem;
    }

    .react-datepicker__month{
        width: 100%;
        margin: 0.5rem 0;
    }
    
    .react-datepicker__month-wrapper{
        display: flex;
        justify-content: space-between;
        flex: 1;
        width: 100%;
        margin-bottom: 1rem;
    }

    .react-datepicker__month-text{
        @include primaryP();
        font-size: 1.6rem;
        width:100%;
        padding: 0.5rem;
    }

    .react-datepicker__month--selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__month--selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover{
        background-color: $primary;
        color:$light0;
    }
}


.datePickerWrap :global {
    
    .react-datepicker__month-container{
        width: 26rem;
    }

    .react-datepicker .react-datepicker__day-name, .react-datepicker .react-datepicker__day {
        @include primaryP();
        font-size: 1.4rem;
        padding: .5rem;
        font-weight: 500;
        width: 2rem;
    }

    .react-datepicker .react-datepicker__day {
        font-weight: 300;
    }
}