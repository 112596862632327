@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.resultItemEven {
	background-color: $light1;
}

.resultItemOdd {
	background-color: $light0;
}
.select {
	width: 200px;
}