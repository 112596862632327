@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
	display: flex;
	justify-content: space-between;

	@media #{$mobile-only} {
		flex-direction: column;
	}
}

.resultsContainer {
	width: 49%;

	@media #{$mobile-only} {
		width: 100%;
	}
}

.upcomingContainer {
	width: 49%;

	@media #{$mobile-only} {
		margin-top: 1rem;
		width: 100%;
	}
}

.results {
	background-color: red;
}