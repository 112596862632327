@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.submenu {
    height: 44px;
    width: 100%;
    background-color: $light2;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 99998;
    overflow: hidden;

    @media #{$tablet-landscape-only},
    #{$tablet-portrait-only} {}

    @media #{$laptop-up} {}

    @media #{$mobile-only} {
        top: 72px;
    }


}

.spacer {
    height: 44px;
}

.row {
    justify-content: flex-end;
}

.menuCol {
    overflow: hidden;
    flex-grow: 1;
}

.menuCol :global .flex-inner {
    overflow-x: scroll;
}


.searchCol {
    display: none;
    flex-shrink: 0;

    @media #{$laptop-up} {
        display: flex;
    }
}

.search {
    display: flex;
    flex-direction: row;
    height: 48px;
    width: 100%;
    background-color: $dark3;
    flex-shrink: 0;
    position: relative;
}

.searchInput {
    padding: 0 15px;
    height: 48px;
    display: block;
    width: 245px;
    box-sizing: border-box;
    color: $light0;
    border: 0;
    background-color: transparent;

    &::placeholder {
        color: $light3;
        font-family: $fontPrimary;
    }

    @media #{$tablet-landscape-up} {
        width: 230px;
    }

    @media #{$laptop-up} {
        width: 230px;
    }

    @media #{$desktop-up} {
        width: 245px;
    }

}

.searchIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto;
    color: $light0;

}