@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.box {
    background: $panelBg;
}

.createText {
    @include secondaryP();
    color: $dark0;
    font-size: 17px;
    font-weight: 500;
    margin-top: 1rem;
}

.createText :global a {
    color: $primary;
}