@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.box {
    background: $panelBg;
    min-height: 600px;
}

.form {
    justify-content: center;
    overflow: hidden;
}

.cover {
    min-height: 360px;
    display: flex;
}

.cover :global .flex-box {
    height: 100%;
}

.cover :global .flex-bg {
    min-height: 100%;
    object-fit: "cover";
}

.text {
    position: relative;
    @include secondaryH2();
    color: $light0;
    font-size: 50px;
    line-height: 70px;
    letter-spacing: .1px;
    z-index: 1;
    padding: 2.5rem 4rem;

    @media #{$mobile-only} {
        font-size: 36px;
        line-height: 50px;
    }
}