@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.heading {
    @include secondaryH1();
    padding-bottom: 10px;
}

.title {
    @include secondaryP();
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 16px;
    color: $primary;
}