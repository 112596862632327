@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
	background-color: $light2;
}

.dividerContainer {
	background-color: $dark1;

	.dividerText {
		color: $light0;
	}

}

.select {
	width: 200px;
}