@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrapper {
    width: 100%;
}

.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-height: 225px;
    border-radius: 10px;
    overflow: hidden;

}

.title {
    @include primaryH1();
    color: $light0;
    z-index: 1;
    letter-spacing: -0.3px;
}

.menu {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
}

.menuText {
    @include primaryP();
    color: $dark1;
    letter-spacing: 0.4px;
}