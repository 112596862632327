@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    flex-direction: row;
}

.dot {
    width: 0.6rem;
    height: 0.6rem;
    background-color: #C4C4C4;
    margin-right: 1rem;
    border-radius: 0.6rem
}

.active {
    background-color: $primary;
}