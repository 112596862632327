@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.heading {
    @include secondaryH2();
    text-align: center;
}

.grid {
    display: flex;
    flex-wrap: wrap;

}

.grid :global .item {
    width: 33%;
    padding: .5rem;
    box-sizing: border-box;
    display: flex;
}

.grid :global .item>a {
    width: 100%;
}