@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    background-color: $panelBg;
    width: 100%;
}

.transparent {
    background-color: transparent;
}

.top {
    padding: 2rem 4rem 0;
}


.body {
    padding: 3rem 4rem 3rem;
}

.edge {
    padding: 2rem 0 0;
}

.sides {
    padding-left: 0;
    padding-right: 0;
}

.top :global .flex-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media #{$tablet-portrait-up} {
        flex-direction: row;
        align-items: center;
        max-width: 90vw;
    }
}

.top :global h3 {
    @include secondaryH1();
    overflow: hidden;
}

.left {
    padding-left: 2rem;
    margin-left: -2rem;
    min-height: 4.4rem;
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    @media #{$tablet-portrait-up} {
        max-width: 480px;
        margin-left: 2rem;
        border-left: 1px solid $light4;
        width: 75%;
    }
}

.left :global .line {
    padding-left: 2rem;
    border-left: 1px solid $light4;
    margin-left: 2rem;
    min-height: 4.4rem;
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    @media #{$tablet-portrait-up} {
        width: 50%;
        max-width: 480px;
        margin-left: 2rem;
    }
}

.right {
    justify-content: center;
}

.right :global .link {
    color: $secondary;
}

.rightText {
    color: $dark2;
}

.rightTextLink {
    color: $dark2;
}

.rightTextLink:hover {
    color: $primary;
}