@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.buttonContainer {
    background-color: $dark2;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
}

.text {
    @include primaryP();
    color: $light0;
    font-size: 11px;
    font-weight: 500;
}