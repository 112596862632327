@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    background-color: $light0;
}

.form {}

.sectionHeading {
    @include primaryH2();
    margin-top: 1rem;
}

.sectionText {
    @include primaryP();
    margin-top: .5rem;
}