@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    overflow: hidden;
    width: 100%;
    padding-bottom: 20px;

    @media #{$mobile-only} {
        height: 200px;
    }

    @media #{$tablet-portrait-up} {
        height: 260px;
    }

    @media #{$tablet-landscape-up} {
        height: 260px;
    }

    @media #{$laptop-up} {
        height: 360px;
    }
}

.wrap :global .slick-list {
    overflow: visible;
}

.wrap :global .slick-slide {
    transition: .3s ease all;
}

.wrap :global .slick-slide:not(.slick-active) {
    opacity: .5;
}

.bulletContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bulletActive {
    width: 56px;
    height: 6px;
    background-color: $primary;
    margin-top: 1.5rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    cursor: pointer;
}

.bullet {
    width: 56px;
    height: 6px;
    background-color: $light3;
    margin-top: 1.5rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    cursor: pointer;
}