@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.startsCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 96px;
    padding: 0 1rem;
    background-color: $light0;
    box-sizing: border-box;
}

.profileContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;

    .image {
        width: 64px;
        height: 64px;
        padding-right: 1rem;
        border-radius: 100%;
    }

    .nameContainer {
        padding: 1rem 1rem 0 0;
        width: 160px;
    }

    .nameText {
        @include primaryH2();
        padding: 0.7rem 0;
    }

    .nameHead {
        display: flex;
        @include primaryH4();
        color: $secondary;
        flex-direction: row;
        margin-right: 10;
    }

}

.scrollContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 96px;
    width: 100%;

    .nameText {
        @include primaryH2();

    }

    .nameHead {
        @include primaryH4();
        color: $secondary;
        padding-bottom: 1rem;
        width: 50px;
    }

    .itemsContainer {
        display: flex;
        flex-direction: row;
        flex: 1;
        height: 96px;
        align-items: center;
        text-align: center;

    }

    .itemContainer {
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;

    }

}