@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    padding: 2rem 0;
}

.text {
    white-space: pre-wrap;
    line-height: 1.6;

    h1 {
        @include primaryH1();
        line-height: 1.6em;
    }

    h2 {
        @include primaryH2();
        padding-bottom: 2rem;
        line-height: 1.6em;
    }

    h3 {
        @include primaryH3();
        line-height: 1.6em;
    }

    h4 {
        @include primaryH4();
        line-height: 1.6em;
    }

    h5 {
        @include primaryH5();
        line-height: 1.6em;
    }

    p {
        @include primaryP();
        padding-bottom: 1.5rem;
        line-height: 1.6em;
    }

    a {
        @include primaryP();
        color: $primary;
        text-decoration: underline;
    }

    ul {
        @include primaryP();
        display: list-item;
        list-style-type: none;
        margin-left: 4rem;
        padding-bottom: 2rem;

        @media #{$mobile-only} {
            margin-left: 3rem;
        }

    }

    li {
        position: relative;
        margin-bottom: 0.5rem;
        line-height: 1.6em;

        &::before {
            content: '•';
            position: absolute;
            @include secondaryP();
            top: 0.1rem;
            left: -15px;
        }

        span {
            @include primaryP();
        }
    }

    ol {
        @include primaryP();
        padding-left: 3rem;
        padding-bottom: 0.5rem;
    }

    strong {
        font-weight: 600;
    }


}