@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.formInner {
    position: relative;
    display: flex;
    margin-inline: 10px;
    margin-top: 1rem;
    color: $dark0;
    // align-items: center;
}

.formInner :global textarea {
    // padding-right: 5rem;
    background: transparent;
    // border-bottom: 1px solid ;
    border-radius: 0;
    padding-left: 0;
    padding-top: 0;
    font-size: 12px;
    height: auto;
    font-weight: 300;

    resize: none;
    // width: 280px;
    // max-width: 100%;
    height: max-content;
}

.formInner ::placeholder {
    color: $dark0;
    opacity: 1;
}

.profileImage {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.formProfileImage {
    margin-right: 10px;
}

.formInput {
    box-sizing: border-box;
}

.spacer {
    border-top: 1px solid $light3;
    width: 100%;
}

.formMisc {
    display: flex;
    justify-content: space-between;
}

.buttonDisabled {
    line-height: 0;
    // position: absolute;
    right: 1rem;
}

.button {
    cursor: pointer;
}

.button :hover {
    color: $primary;
}

.icon {
    // color: $dark3;
    width: 24px;
    height: 24px;
}

.loading {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    color: $primary;
}

.loading :global .css-0 div {
    background-color: currentColor;
}

.emojiWrap {
    position: relative;

}

.emojiPicker {
    position: absolute;
    bottom: 100%;
    left: 0;
}