@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    width: 50%;
}

.backContainer {
    display: flex;
    flex-direction: row;
    padding: 4rem 2.5rem;
}

.icon {
    font-size: 20px;
    color: $dark1;
    margin-right: 0.8rem;
    cursor: pointer;
}

.back {
    display: flex;
    flex-direction: row;
}

.backText {
    @include secondaryH1();
    font-size: 17px;
    line-height: 20px;
    font-weight: 400;
    margin-right: 0.4rem;
    cursor: pointer;
}

.headingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4rem 0 2rem;
}

.headingText {
    @include primaryP();
    color: $dark0;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 2rem;
}

.headingTextAlt {
    @include primaryP();
    color: $dark3;
    font-size: 18px;
    font-weight: 500;
}

.selectText {
    @include secondaryP();
    color: $primary;
    font-size: 17px;
    font-weight: 500;
}

.box {
    //background: $panelBg;
}

.teamPanel {
    display: flex;
}

.content {
    display: flex;
    margin-left: 2rem;
    flex-direction: column;
    justify-content: center;
}

.seasonTitle {
    @include secondaryH2();
    font-size: 1.7rem;
    font-weight: 600;
}

.startDate {
    @include primaryP();
    color: $primary;
    font-weight: 500;
    top: -2px;
    position: relative;
}

.description {
    @include secondaryP();
}