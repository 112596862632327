@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.contentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sliderContainer{
   margin-bottom: 2rem;
}

.descriptionContainer{
    width: 70%;
    padding: 2rem 0;
}

.header{
    flex: 1;
    display: flex;
    background-color: $light3;
    height: 80px;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.headingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;
}

.footerContainer{
    background-color: $light2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
}

.headerText{
    @include secondaryH2();
    padding: 1rem 0;
}

.descriptionText{
    @include primaryP();
    text-align: center;
}

.arrowText{
    @include primaryP();
    text-align: center;
}

.footerTitle{
    @extend .headerText;
    font-size: 22px;
}

.footerContent{
    @extend .descriptionText;
}

.hostText{
    font-family: $fontSecondary;
    font-size: 11px;
    color: $dark3;
    padding: 0.5rem 0;
}

.titleText{
    font-family: $fontPrimary;
    font-size: 24px;
    font-weight: 200;
    color: $primary;
    padding: 0.5rem 0;
}

.coachNameText{
    @extend .hostText;
    font-weight: 600;
    color: $dark1;
    text-transform: uppercase;
}

.avatar{
    width: 70px;
    height: 70px;
    border-radius: 35px;
}

.button{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.left {
    cursor: pointer;
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;

}

.left:hover .iconLeft{
    color: $primary;
}

.right {
    cursor: pointer;
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
}

.right:hover .iconRight{
    color: $primary;
}

.iconRight {
    color: $light3;
    font-size: 40px;
    vertical-align: middle;
    align-self: center;
    margin-left: 0.4rem;
}

.iconLeft {
    color: $light3;
    font-size: 40px;
    vertical-align: middle;
    align-self: center;
    margin-right: 0.4rem;
}

.slickButtons {
    position: absolute;
    top: 100%;
    margin: auto;
    font-size: 14px;
    color: $dark1;
    padding: 1rem 2rem;
    cursor: pointer;
    transform: translate(0, -50%);
    z-index: 9;
}

.slickButtons:hover {
    color: $primary;
}

.slickNext {
    right: -2rem;
}

.slickPrev {
    left: -2rem;
}

.image{
    width: 65px;
    height: 65px;
    border-radius: 50%;
}
