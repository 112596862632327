@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.dividerContainer {
    background-color: $dark1;

    .dividerText {
        color: $light0;
    }

}

.tableContainer {
    padding-bottom: 2rem;
}