@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.heading {
    @include secondaryH2();
}

.subHeading {
    @include secondaryH3();
    color: $dark3;
    padding-top: .5rem;
}

.subHeading :global a {
    color: $primary;
}