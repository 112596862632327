@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.bannerWrapper {
    width: 100%;
    background-color: $secondary;
}
.container {
    position: relative;
    min-height: 238px;
    display: flex;
    align-items: center;
    padding: 2.8rem;
    box-sizing: border-box;
    justify-content: center;

}

.heading {
    @include secondaryH1();
    z-index: 1;
    color: $light0;
}