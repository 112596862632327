@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.header {
    background-color: $light0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    border-bottom: 1px solid $light6;
    padding: 0 15px;
    box-sizing: border-box;

    @media #{$mobile-only} {
        justify-content: space-between;
    }

    @media #{$laptop-up} {
        padding: 0 4rem;
    }
}

.spacer {
    display: block;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 2rem 0 0;
}

.logo .logoLarge {
    display: flex;
    width: 90px;
    height: 28px;
}

.userImage {
    display: flex;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 50%;
}


.search {
    display: flex;
    align-items: center;
}

.searchForm>fieldset {
    display: flex;
    align-items: center;
    gap: .8rem
}

.searchInput {
    font-family: $fontSecondary  !important;
    height: 40px !important;
    background-color: $light1  !important;
    border: 1px solid $light3;
    font-weight: 500 !important;
    font-size: 16px !important;
    letter-spacing: .5px !important;
    padding-left: 1rem !important;

    @media #{$laptop-up} {
        width: 260px !important;
    }

    @media #{$tablet-landscape-only} {
        width: 190px !important;
    }

    @media #{$mobile-only},
    #{$tablet-portrait-only} {
        display: none !important;
    }
}

.searchIcon {
    width: 24px;
    height: 24px;
    padding-right: 8px;
    margin-right: 8px;
    cursor: pointer
}

.activeLink {
    @extend .link;
}

.active {
    color: $primary  !important;
}

@media #{$mobile-only},
#{$tablet-portrait-only},
#{$tablet-landscape-only} {
    .logoSmall {
        height: 28px;
        display: block;
    }

    .logoLarge {
        display: none !important;
    }
}

@media #{$laptop-up} {
    .logoSmall {
        display: none;
    }

}

@media #{$mobile-only} {

    .header {
        height: 72px;
    }

    .logo {
        flex: 1;
        justify-content: flex-start;
    }

    .spacer {
        height: 72px;
    }

    .icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .toggle {
        padding: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: $dark1;
        align-items: center;
    }

    .icon {
        font-size: 28px;
    }

    .toggle:hover {
        color: $primary;
    }

    .linksWrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 1.1rem;
        flex: 1;
    }

    .links {
        display: flex;
        position: fixed;
        flex-direction: column;
        top: 0;
        right: 0;
        width: 280px;
        height: 100vh;
        background: $light0;
        z-index: 999998;
        transform: translateX(100%);
        transition: .4s ease all;
        overflow-y: scroll;
        box-sizing: border-box;
        padding: 20px 0 0 0;
    }

    .profile {
        order: 1;
        z-index: 999999;
        display: none;
    }

    .sidebarUser {
        padding: 20px;
        background-color: $light3;
    }

    .sidebarProfile {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .sidebarProfile .userImage {
        width: 24px;
        height: 24px;
    }

    .sidebarProfile span {
        @include primaryP();
        font-size: 11px;
        color: $dark1;
    }

    .link {
        display: flex;
        padding: 0 20px;
        height: 48px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        @include secondaryP();
        color: $dark1;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        box-sizing: border-box;
        width: 100%;
    }

    .logoLink {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .logoLink .closeIcon {
        font-size: 36px;
    }

    .activeLink {
        color: $primary  !important;
        font-weight: 600 !important;
        font-size: 17px !important;
    }

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: 100vh;
        background: rgba($dark1, .5);
        z-index: 999997;
        transition: .2s .4s ease opacity;
        transform: translateX(100%);
        opacity: 0;
    }

    .header.open .links {
        transform: translateX(0);
        opacity: 1;
    }

    .header.open .overlay {
        transform: translateX(0);
        opacity: 1;
    }

    .sidebarSpacer {
        height: 60px;
        background-color: $light3;
    }

}

@media #{$laptop-up},
#{$tablet-portrait-only},
#{$tablet-landscape-only} {

    .header {
        height: 80px;
    }

    .spacer {
        height: 80px;
    }

    .icons {
        display: none;
    }

    .linksWrap {
        display: flex;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 1.1rem;
        flex: 1;
    }

    .links {
        flex: 1;
    }

    .close {
        display: none;
    }



    .link {
        display: flex;
        padding: 0 1.6rem;
        margin: 0 .5rem;
        height: 80px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @include secondaryP();
        color: $dark1;
        font-weight: 600;
        font-size: 17px;
        text-align: center;

        &:hover:not(.active) {
            position: relative;

            &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                background-color: $primary;
                top: 0;
            }
        }
    }

    .logoLink {
        display: none;
    }

    .profile {
        order: 1;
        display: flex;
        align-items: center;

        @media #{$tablet-portrait-only} {
            .username {
                display: none;
            }
        }
    }

    .activeLink {
        display: none;
    }

    .sidebarUser {
        display: none;
    }
}

@media #{$tablet-portrait-only} {
    .searchIcon {
        font-size: 48px;
    }
}