@import "@dex/bubl-web/src/styles/Global.scss";

$primary: #ff4e39;
$secondary: #5f7a9e;
$alt: #f2c94c;

$primaryLight: #f5e8e6;
$primaryDark: #02004a;

$light0: white;
$light1: #f9f9f9;
$light2: #eff2f6;
$light3: #dfe4ea;
$light4: #c6c6c8;
$light5: #bfbfbf;
$light6: #bdbdbd;

$dark0: black;
$dark1: #383b3c;
$dark2: #595c5e;
$dark3: #6f7781;
$dark4: #747380;
$dark5: #898894;

$purpleHl: #3d5afe;
$yellowHl: #ffc107;
$orangeHl: #ff5722;
$greenHl: #27AE60;
$blueHl: #2F80ED;
$pinkHl: #000000;
$redHl: #f5222d;
$followBtn: #ffbc39;

$bodyBg: $light2;

$modalBg: rgba($dark0, 0.5);

$panelBg: $light0;
$panelBdr: $light3;
$panelText: $bodyText;
$panelHeading: $headingText;

$fontPrimary: "Interstate";
$fontSecondary: "AvenirNextCondensed",
"Interstate",
"Poppins";
$fontTertiary: "Poppins";

@mixin headline() {
    font-family: $fontSecondary;
    color: $dark1;
    font-weight: 600;
    font-size: 48px;
    line-height: 66px;
    letter-spacing: -0.5px;
}

@mixin primaryH1() {
    font-family: $fontPrimary;
    color: $dark1;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.5px;
}

@mixin primaryH2() {
    font-family: $fontPrimary;
    color: $dark1;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.5px;
}

@mixin primaryH3() {
    font-family: $fontPrimary;
    color: $dark1;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.8px;
}

@mixin primaryH4() {
    font-family: $fontPrimary;
    color: $dark1;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
}

@mixin primaryH5() {
    font-family: $fontPrimary;
    color: $dark1;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.4px;
}

@mixin primaryP() {
    font-family: $fontPrimary;
    color: $dark1;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.3px;
}

@mixin secondaryH1() {
    font-family: $fontSecondary;
    color: $dark1;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.5px;
}

@mixin secondaryH2() {
    font-family: $fontSecondary;
    color: $dark1;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.3px;
}

@mixin secondaryH3() {
    font-family: $fontSecondary;
    color: $dark1;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
}

@mixin secondaryH4() {
    font-family: $fontSecondary;
    color: $dark1;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
}

@mixin secondaryH5() {
    font-family: $fontSecondary;
    color: $dark1;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.4px;
}

@mixin secondaryP() {
    font-family: $fontSecondary;
    color: $dark1;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.4px;
}

@mixin tertiaryH2() {
    font-family: $fontTertiary;
    color: $dark1;
    font-weight: bold;
    font-size: 72px;
    line-height: 72px;
    text-transform: uppercase;
}

@mixin tertiaryH3() {
    font-family: $fontTertiary;
    color: $dark1;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
}

@mixin tertiaryH4() {
    font-family: $fontTertiary;
    color: $dark1;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    text-transform: capitalize;
}

@mixin tertiaryH5() {
    font-family: $fontTertiary;
    color: $dark1;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    text-decoration-line: underline;
    text-transform: lowercase;
}

.headline {
    @include headline();
}

.primaryH1 {
    @include primaryH1();
}

.primaryH2 {
    @include primaryH2();
}

.primaryH3 {
    @include primaryH3();
}

.primaryH4 {
    @include primaryH4();
}

.primaryH5 {
    @include primaryH5();
}

.primaryP {
    @include primaryP();
}

.secondaryH1 {
    @include secondaryH1();
}

.secondaryH2 {
    @include secondaryH2();
}

.secondaryH3 {
    @include secondaryH3();
}

.secondaryH4 {
    @include secondaryH4();
}

.secondaryH5 {
    @include secondaryH5();
}

.secondaryP {
    @include secondaryP();
}

.tertiaryH2 {
    @include tertiaryH2();
}

.tertiaryH3 {
    @include tertiaryH3();
}

.tertiaryH4 {
    @include tertiaryH4();
}

.tertiaryH5 {
    @include tertiaryH5();
}