@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    width: 100%;
    aspect-ratio: 16/9;
    position: relative;
}

.loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $primary;
    z-index: 0;
}

.loading :global .css-0 div {
    background-color: currentColor;
}

.frame {
    position: relative;
    z-index: 10;
}