@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    flex: 1;
    width: 100%;
    height: 300px;
    display: flex;
    z-index: 1;
    background-color: $light0;
    flex-wrap: wrap;
}


.col {
    background: $light0;
    padding-bottom: 2rem;
}

.colInner {
    background: $light0;
}

.contentContainer {
    padding: 2rem;
}

.headingContainer {
    padding-bottom: 0.5rem;
}

.headingContainerAlt {
    padding-top: 3rem;
    padding-bottom: 0.5rem;
}

.descriptionContainer {
    display: flex;
    flex-direction: row;
    padding-top: 0.8rem;
}

.headingText {
    @include primaryH1();
    color: $dark1;
    padding-bottom: 1rem;
}

.contentText {
    @include primaryH5();
    color: $dark1;
    font-size: 14px;
    line-height: 17px;
}