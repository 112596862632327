@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light3 !important;
    padding: 1.2rem 0;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 1;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: left;
    width: 15rem;
    margin-right: 2rem;
}

.imageContainer {
    padding-right: 2rem;
}

.logo {
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.tagTitle {
    @include secondaryH5();
    text-transform: uppercase;
    color: $primary;
}

.text {
    @include primaryP();
}

.popup {
    position: relative;
    background: $panelBg;
}

.close {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2.2rem;
}

.modelContainer {
    padding: 2rem;
}

.sessionsContainer {
    max-height: 400px;
    overflow-y: auto;
}

.timerIcon {
    cursor: pointer;
}
.closeButton{
    font-family: $fontPrimary;
    font-weight: 300;
    margin-top: 2rem;
}