@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    margin: 10px 10px 0;
}

.text {
    @include primaryP();
    font-size: 13px;
}