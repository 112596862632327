@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
    background-color: $dark3;
}

.cartIcon {
    font-size: 1.4rem;
}

.videoIcon {
    font-size: 2.6rem;
    color: $light0;
    margin-right: 1rem;
}

.streamTitle {
    @include primaryP();
    color: $light2;
    font-size: 1.8rem;
    font-weight: 600;
}