@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.bookings {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.6rem;
}

.session {
    flex-basis: 100%;

    @media #{$tablet-portrait-up} {
        flex-basis: 48%;
    }

    @media #{$laptop-up} {
        flex-basis: 32%;
    }

}