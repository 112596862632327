@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.bracketWrap {
  background: transparent;
}

.bracketInner {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  min-width: 190px;
  flex: 1;
  justify-content: space-around;
  position: relative;
  padding: 10px 0 0;
  margin: 0 10px 0;
}

.columnFirst {
  margin-left: 0;
}

.columnLast {
  margin-right: 0;
}

.columnTitle {
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  color: $dark3;
}

// // Item

.item {
  margin: 2.5rem 0;
  background-color: $dark3;
  border-radius: 1.5rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.itemMatchupKey {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  bottom: -2rem;
  font-size: 11px;
  color: $dark3;
}

.itemContainer {
  display: flex;
  overflow: hidden;
}

.separator {
  height: 1px;
  background-color: $dark5;
}

.itemSeed {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  flex-shrink: 0;
  background-color: #555b64;
  width: 4rem;
  min-height: 4.5rem;
}

.seedText {
  color: $dark3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  @include secondaryH4();
  color: $light0;
}

.itemContent {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px;
  //background-color: $dark3;
}

.logo {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1rem;
  border-radius: 50%;
}

.name {
  color: $dark2;
  margin-right: 1rem;
  flex-grow: 2;
  overflow: hidden;
}

.nameText {
  @include secondaryH3();
  color: $light0;
  line-height: 1.2em;
  max-height: 1.2em;
}

.score {
  margin-right: 1rem;
}

.scoreText {
  @include secondaryH3();
  color: $light0;
}

.winnerText {
  color: $alt;
}


//Matchup Modal

.popup {
    position: relative;
    background: $panelBg;
}

.close {
    position: absolute;
    top: 22px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2.2rem;
}