@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.profileContainer {
    flex-direction: column;
    padding-bottom: 3rem;
}

.nameContainer {
    margin-top: 1.5rem;
}

.avatarContainer {
    margin: 1rem 0;
    width: 25%;
}

.athletesContainer {
    padding-bottom: 3rem;
}

.buttonContainer {
    width: 30%;
    justify-content: center;
    align-self: center;
}

.avatar {
    width: 140px;
    height: 140px;
    background: #DFE4EA;
    border-radius: 100%;

    @media #{$mobile-only} {
        width: 100px;
        height: 100px;
    }
}

.heading {
    @include secondaryH1();
}

.name {
    @include secondaryH2();
    font-weight: 600;
    color: $dark1;
}

.link {
    @include secondaryH4();
    color: $secondary;
}

.atheleteText {
    @include secondaryH3();
    color: $dark1;
    padding-bottom: 2rem;
}

.popup {
    position: relative;
    background: $panelBg;
}

.close {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2.2rem;
}