@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    background-color: $panelBg;
}

.title {
    @include primaryH1();
    line-height: 50px;
    font-size: 36px;
    font-weight: 600;
    color: $dark1;
}

.titleContainer {
    padding: 0 4rem;
}