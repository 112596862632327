@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.bar {
    display: flex;
    background-color: $primary;
    width: 100%;
    height: 60px;
    min-height: 60px;
    justify-content: center;
    align-items: center;
}

.text {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    @include primaryP();
    color: $light0;
    text-align: center;
    font-weight: 500;
}

.text:hover {
    color: $dark0;
    text-decoration: underline;
}