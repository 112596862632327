@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.body {
    padding: 4rem;
    background-color: $light0;
}

.text {
    text-align: center;
    @include primaryP();
}

.image {
    margin: 0 auto;
    width: 160px;
    margin-bottom: 3rem;
}