@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.card {
    display: block;
    text-align: center;
    padding: 2rem 1rem;
    border: 2px solid transparent;
    cursor: pointer;
    transition: .3s ease all;
}

.card:hover {
    background: $panelBg;
    border-color: $primary;
}

.selected {
    background: $panelBg;
    border-color: $panelBdr;
}

.image {
    width: 60%;
    max-width: 70px;
    height: auto;
    margin: 0 auto 1.5rem;
}

.title {
    @include secondaryH4();
}