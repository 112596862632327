@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    padding: 2rem 0;
}

.nameContainer {
    flex-grow: 1;
    flex-shrink: 1;
    padding-top: 2px;
}

.buttonContainer {
    width: 16rem;
}

.headerText {
    @include secondaryP();
    font-weight: 500;
    color: $primary;
}

.nameText {
    @include primaryH3();
}

.helpText {
    @include primaryH5();
    margin-top: 2px;
    text-align: center;
}

.image {
    width: 48px;
    height: 48px;
    border-radius: 40px;
    margin-right: 8px;
}