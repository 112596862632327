@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    border-bottom: 1px solid $light3 !important;
    padding: 1.2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-grow: 1;
    flex-shrink: 1;
}

.imageContainer {
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: left;
    width: 15rem;
    margin-right: 2rem;
}

.iconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image {
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.icon {
    width: 35px;
    height: 35px;
    margin-left: 1rem;
    cursor: pointer;
}

.headingText {
    @include secondaryP();
    font-size: 11px;
    color: $primary;
}

.subHeadingText {
    @include primaryP();
    font-size: 14px;
}

.box {
    background-color: yellow;
}

.popup {
    position: relative;
    background: $panelBg;
}

.close {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2.2rem;
}