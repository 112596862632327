@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    align-items: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.date {
    display: flex;
    width: 6.5rem;
    height: 6.5rem;
    flex-direction: column;
    background-color: $light2;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    margin-right: 1.6rem;
}

.dateDay {
    @include secondaryP();
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.6rem;
}

.titleText {
    @include secondaryH2();
    font-weight: 600;
    color: $primary;
    font-size: 1.6rem;
    padding-bottom: 0.4rem;
}

.groupTitleText {
    @include secondaryH2();
    font-weight: 600;
    font-size: 1.4rem;
}

.locationText {
    @include secondaryP();
    font-weight: 300;
    font-size: 1.4rem;
    color: $dark0;
}


.duration {
    display: flex;
    flex-direction: row;
}

.start {
    @include secondaryP();
    font-weight: 300;
    font-size: 1.4rem;
    color: $primary;
}

.end {
    @extend .start;
    margin-left: 1.5rem;
}