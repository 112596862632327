@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container{
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}