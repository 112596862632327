@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $panelBg;
}