@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.heading {
    @include secondaryH1();
}

.table {
    width: 100%
}

.table :global {

    .sticky-table-row {
        vertical-align: middle;
    }

    .head .sticky-table-cell {
        @include secondaryP();
        text-transform: uppercase;
        color: $light5;
        font-weight: 500;
    }

    .sticky-table-cell {
        padding: 2rem 1rem;
        width: 10%;
        border: 0px;
        border-bottom: 1px solid $light3 !important;
        border-radius: 0 !important;
        white-space: nowrap;
        @include secondaryH4();
    }

    .sticky-table-cell a {
        color: currentColor;
    }

    .sticky-table-cell.total {
        font-weight: 600;
    }

    .item {
        display: flex;
        align-items: center;
    }

}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: $primary;
    border-radius: 16px;
    color: $light0;
    font-size: 18px;
    transform: rotate(140deg);
    margin-right: 1rem;
}