@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.popup {
    position: relative;
    background: $panelBg;
}

.body {
    padding: 4rem;
}

.sessions {
    margin: 1rem -2rem 0rem;
}

.close {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2.2rem;
}

.timeSlots {
    width: 100%;
}

.timeSloTitle {
    @include secondaryP();
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 1rem;
}

.cart {
    padding: 1.5rem 1.2rem;
}

.backLink {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.backIcon {
    margin-right: .5rem;
}