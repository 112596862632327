@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.slider {
    background-color: $light0;
    width: 42rem;
    padding: 22px 22px 0 22px;

    @media #{$mobile-only} {
        width: calc(100% - 45px);
    }
}

.slide {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 640px;

    @media #{$mobile-only} {
        height: 490px;
    }
}

.slideAlignTop {
    @extend .slide;
    justify-content: flex-start;
}

.slideAlignBottom {
    @extend .slide;
    justify-content: space-between;
}

.slideAlignCenter {
    @extend .slide;
    justify-content: center;
}

.wizard {
    position: relative;
    flex: 1;
    padding-bottom: rem(0px)
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 34px;
    width: 42rem;
    box-sizing: border-box;

    @media #{$mobile-only} {
        width: calc(100% - 45px);
    }
}

.footerDots {
    justify-content: center;
}

.progressBarContainer {
    width: 100%;
    position: relative;
}

.progressBar {
    height: 5px;
    width: 100%;
    background-color: $light2;
}

.closeText {
    @include secondaryP();
    font-size: 1.4rem;
    font-weight: 400;
    color: $dark0
}

.closeLink {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.2rem;
    justify-content: flex-end;
    cursor: pointer;
}

.titleText {
    @include secondaryH5();
    font-size: 1.2rem;
    color: $primary;
    font-weight: 600;
}

.titleBar {
    padding-bottom: 0.8rem;
}

.textBold {
    @include primaryP();
    font-size: 1.4rem;
    color: $dark3;
    font-weight: 600;
}

.text {
    @include primaryP();
    font-size: 1.4rem;
    color: $dark3;
}

.heading {
    @include secondaryH3();
    font-weight: 500;
    font-size: 2.2rem;
    color: $primary;
    margin-bottom: 0.8rem;
}