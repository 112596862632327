@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    padding: 2rem;
    background-color: $light0;
    border: 1px solid $light3;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-bottom: 20px;
}

.hostName {
    font-family: $fontSecondary;
    font-size: 11px;
    color: $dark3;
    padding-bottom: 3px;
}

.title {
    font-family: $fontPrimary;
    font-size: 17px;
    color: $primary;
    padding-bottom: 5px;
}

.date {
    font-family: $fontSecondary;
    font-size: 16px;
    font-weight: 500;
    color: $dark3;
}

.coachContainer {
    display: flex;
    flex-direction: row;
    padding-bottom: 24px;
}

.placeholder {
    padding-right: 24px;
    font-family: $fontSecondary;
    font-size: 14px;
    color: $light5;
}

.coach {
    font-family: $fontSecondary;
    font-size: 16px;
    font-weight: 500;
    color: $dark3;
}

.link {
    display: flex;
    padding-top: 2rem;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-family: $fontSecondary;
    font-size: 14px;
    color: $dark1;

    &:hover {
        color: $primary;
    }
}

.icon {
    margin-right: 5px;
    width: 25px;
    height: 25px;
    color: $primary;
}

.popup {
    position: relative;
    background: $panelBg;
}

.close {
    position: absolute;
    top: 30px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2.2rem;
}

.popupContainer {
    flex: 1;
    display: flex;
    background-color: $light3;
    height: 80px;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.rangeSlider {
    margin: 2rem 0;
}