@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    padding: 2rem;
}

.slickButtons {
    position: absolute;
    top: 50%;
    margin: auto;
    font-size: 40px;
    color: $light3;
    cursor: pointer;
    transform: translate(0, -50%);
    z-index: 9;
}

.slickButtons:hover {
    color: $primary;
}

.slickNext {
    right: -2rem;
}

.slickPrev {
    left: -2rem;
}

.itemContainer {
    padding: 2rem 4rem;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
}

.itemContainer:focus {
    outline: none;
}

.profileContainer {
    display: flex;
    align-items: center;

    @media #{$mobile-only} {
        flex-direction: column;
    }
}

.image {
    margin-right: 40px;
    width: 140px;
    height: 140px;
    border-radius: 100px;

    @media #{$mobile-only} {
        margin-right: 0;
    }
}

.name {
    @include secondaryH2();
    margin-bottom: 1.5rem;

    @media #{$mobile-only} {
        text-align: center;
    }
}

.starContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.star {
    font-size: 25px;
    color: $light3;
    margin-bottom: 1.5rem;
}

.date {
    @include secondaryH4();
    color: $secondary;
    text-align: center;
    @media #{$mobile-only} {
        text-align: center;
    }
}

.contentWrapper {
    max-width: 60%;
    padding: 4rem;
    text-align: center;
}

.content {
    @include secondaryH3();
    font-style: italic;
}


.startEmpty{
    color: $light3;
}

.StarFill {
    color:$primary;
}