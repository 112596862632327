@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.imageContainer>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
}

.item {
    flex: 0 33.33%;
    max-width: 33.33%;
    box-sizing: border-box;
    overflow: hidden;
}

.image {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    background: $light3;
}

.imageTag{
    @include secondaryP();
    padding: 10px 0px;
    font-size: 16px;
}

.panel{
    background-color: transparent;
}

.rightText{
    @include secondaryP();
    color: $secondary;
    font-size: 22px;
    text-align: right;
    font-style: italic;
    font-weight: 500;
}

.top{
    padding: 2rem 0 0 0;
}