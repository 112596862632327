@font-face {
    font-family: 'AvenirNextCondensed';
    src: url('../assets/fonts/AvenirNextCondensed-Bold.woff2') format('woff2'),
        url('../assets/fonts/AvenirNextCondensed-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextCondensed';
    src: url('../assets/fonts/AvenirNextCondensed-Regular.woff2') format('woff2'),
        url('../assets/fonts/AvenirNextCondensed-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextCondensed';
    src: url('../assets/fonts/AvenirNextCondensed-Medium.woff2') format('woff2'),
        url('../assets/fonts/AvenirNextCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Interstate";
    src: url("../assets/fonts/Interstate-Regular.woff2") format("woff2"),
        url("../assets/fonts/Interstate-Regular.woff2") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Interstate";
    src: url("../assets/fonts/Interstate-Medium.woff2") format("woff2"),
        url("../assets/fonts/Interstate-Medium.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Interstate";
    src: url("../assets/fonts/Interstate-Bold.woff2") format("woff2"),
        url("../assets/fonts/Interstate-Bold.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Light.woff2") format("woff2"),
        url("../assets/fonts/Poppins-Light.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Regular.woff2") format("woff2"),
        url("../assets/fonts/Poppins-Regular.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Medium.woff2") format("woff2"),
        url("../assets/fonts/Poppins-Medium.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Bold.woff2") format("woff2"),
        url("../assets/fonts/Poppins-Bold.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}