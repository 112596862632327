@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    flex: 1;
    display: flex;
    background-color: $light3;
    height: 60px;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.containerDark {
    @extend .container;
    background-color: $secondary;
}

.headingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.headerText {
    @include secondaryH2();
    color: $dark1;
}

.headerTextDark {
    @extend .headerText;
    color: $light0;
}