@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}

.item {
    @include primaryP();
    padding: 0 1.5rem;
    color: $dark1;
    font-weight: 400;
    height: 44px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    white-space: nowrap;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.item:hover {
    color: $primary;
}

.active {
    color: $primary;
    border-bottom-color: $primary;
}