@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    width: 100%;
    padding-top: 100%;
    position: relative;
    margin: 0 auto;
}

.placeholder {
    border-radius: 50%;
    background: $light3;
    width: 100%;
    padding-top: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
}

.image {
    border-radius: 50%;
    background: $dark2;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.icon {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 20px;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading>div>* {
    background-color: $primary;
}