@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    flex: 1;
    width: 100%;
    height: 500px;
    display: flex;
    z-index: 1;
    align-items: center;
}

.content {
    z-index: 2;
}

.title {
    display: flex;
    padding: 1rem 0rem;
    max-width: 580px;

    @media #{$mobile-only} {
        max-width: 80%;
    }
}

.description {
    display: flex;
    padding: 1rem 0rem;
    max-width: 580px;

    @media #{$mobile-only} {
        max-width: 80%;
    }
}

.titleText {
    @include primaryH1();
    color: $light0;
    font-size: 36px;
    line-height: 50px;
    font-weight: 700;
}

.descriptionText {
    @include primaryH2();
    color: $light0;
    font-size: 15px;
    font-weight: 500;
}

.link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
}