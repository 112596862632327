@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: $light0;
    padding: 2.5rem;
    box-sizing: border-box;
}

.textWrapper {}

.image {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 16px;
}

.title {
    @include primaryP();
    font-size: 14px;
    color: $dark1;
}

.time {
    @include primaryP();
    font-size: 11px;
    color: $dark3;
}

.icon {
    float: right;
    color: $light3;
    font-size: 28px;
    margin-top: -35px;
}