@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.inner {
    padding: 0 1.6rem;
}

.image {
    width: 35rem;
    align-self: center;
}