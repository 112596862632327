@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.headingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8rem 0 2rem;
}

.headingText {
    @include primaryP();
    color: $dark0;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 2rem;
}

.headingTextAlt {
    @include primaryP();
    color: $dark3;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 2rem;
}

.indicator :global .css-0 div {
    background-color: $primary;
}