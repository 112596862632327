@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding: 4rem 0;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}