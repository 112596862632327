@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    width: 100%;
    min-height: 600px;
    max-height: 800px;
    height: 90vh;
    display: flex;
    z-index: 1;
    align-items: center;
    overflow: hidden;
    background: $primary;
}

.contentContainer {
    z-index: 2;
}

.titleContainer {
    padding: 1rem 0;
    max-width: 580px;

    @media #{$mobile-only} {
        max-width: 80%;
    }
}

.titleText {
    @include tertiaryH2();
    color: $light0;

    @media #{$mobile-only},
    #{$tablet-portrait-only} {
        font-size: 46px;
        line-height: 46px;
    }
}

.descriptionContainer {
    padding: 1rem 0;
    max-width: 580px;

    @media #{$mobile-only} {
        max-width: 80%;
    }
}

.descriptionText {
    @include tertiaryH3();
    color: $light0;

    @media #{$mobile-only},
    #{$tablet-portrait-only} {
        font-size: 20px;
    }
}

.ratingContainer {
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
}

.starContainer {
    margin: 0.4rem 0.5rem;
    vertical-align: middle;
}

.star {
    width: 20px;
    height: 20px;
}

.reviewContainer {
    padding: 0rem 1rem;
}

.startEmpty{
    color: $light3;
}

.StarFill {
    color:$light0;
}