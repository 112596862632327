@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {}


.label {
    @include secondaryH4();
    font-size: 15px;
    padding-left: 5px;
    padding-bottom: 5px;
    display: block;
}

.label :global .form-field-label-required {
    color: $redHl;
}

.label :global .form-field-label-required:before {
    content: " ";
}

.input {
    @include primaryP();
    display: block;
    box-sizing: border-box;
    padding: 1rem 1.5rem;
    height: 48px;
    width: 100%;
    background: $light2;
    border-radius: 5px;
}

.inputBigSearch {
    @include primaryP();
    font-size: 10px;
    display: block;
    box-sizing: border-box;
    padding: 1rem 1rem;
    height: 36px;
    width: 300px;

    @media #{$mobile-only} {
        width: 200px;
        height: 36px;
    }
}

.error {
    @include primaryP();
    color: $redHl;
    font-size: 12px;
}

:global {
    .form-field {
        width: 100%
    }

    .react-datepicker__day--disabled{
        color: $light4 !important;
    }
}

.dateIndicator {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    font-size: 1.8rem;
}