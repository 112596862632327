@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.item {
    background-color: $light0;
    box-sizing: border-box;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-left: 5px solid transparent;
    border-bottom: 1px solid $light3;
}

.active {
    border-left-color: $alt;
}

.icon {
    width: 32px;
    height: 32px;
    padding: .5rem;
    border-radius: 100%;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $light0;
    font-size: 20px; // icon size
}

.text {
    margin-left: 1rem;
    line-height: 2.5rem;
}

.heading {
    @include secondaryH4();
}

.desc {
    @include primaryP();
    color: $dark3;
}

.item:hover {
    border-left-color: $primary;
}

.item:hover .heading {
    color: $primary;
}