@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.dropmenu {
    cursor: pointer;
    width: 100%;
    max-width: 14.4rem;
    margin-right: 1rem;
}

.dropmenu :global {
    .drop__control {
        border: 0 !important;
        box-shadow: none !important;
        cursor: pointer;
        background-color: $light2;

        &:hover{
            background-color:$primary;

            & .drop__indicators .drop__indicator{
                color: rgba($light0,0.5);
            }

            & .drop__single-value {
                color: $light0;
            }
        }
    }

    .drop__indicator-separator {
        display: none;
    }

    .drop__value-container {
        padding-left: 1rem;
    }

    .drop__single-value {
        @include secondaryH4();
        font-size: 1.6rem;
        color: $dark3;
    }

    .drop__indicator {
        color: rgba($dark3, 0.5);
    }

    .drop__control--menu-is-open {
        background-color: $primary;
    }

    .drop__control--menu-is-open .drop__single-value {
        color: $light0;
    }

    .drop__control--menu-is-open > .drop__indicators .drop__indicator{
        color:rgba($light0,0.5)
    }

    .drop__menu {
        min-width: 220px;
        max-width: 85w;
        box-shadow: none;
        background: $dark1;
        border-radius: 0.5rem;
        z-index: 999999;
    }

    .drop__option {
        background: none;
        @include secondaryH4();
        color: $light0;
        cursor: pointer;
    }

    .drop__option:hover,
    .drop__option--is-selected {
        color: $alt;
    }
}

.searchDropmenu :global {
    .drop__single-value {
        @include secondaryH4();
        font-weight: 500;
        color: $dark3;
    }
}
