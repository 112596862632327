@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.card {
    text-align: center;
    display: block;
}

.image {
    width: 108px;
    height: auto;
    max-width: 100%;
    margin-bottom: 16px;
}

.title {
    @include secondaryH4();
}