@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    min-width: 100%;
}

.team {
    display: flex;
    align-items: center;
}
.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin-right: 1rem;
}
.teamName {
    text-align: left !important;
}

.table {
    min-width: 100%;
}

.table :global {

    .sticky-table-table {
        min-width: 100%;
    }

    .sticky-table-row {
        vertical-align: middle;
    }

    .head .sticky-table-cell {
        text-transform: uppercase;
        background-color: $dark3;
        color: $light2;
    }

    .sticky-table-cell {
        padding: 2rem 1rem;
        border: 0px;
        border-bottom: 1px solid $light3 !important;
        border-radius: 0 !important;
        white-space: nowrap;
        @include secondaryH4();
        width: 100px;
        text-align: center;
        color: $dark1;
        vertical-align: middle;
    }

    .sticky-table-cell a {
        color: currentColor;
    }

    .sticky-table-cell.team {
        text-align: left;
    }

    .item {
        display: flex;
        align-items: center;
    }
}
