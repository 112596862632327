@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.item {
    display: flex;
    margin: 0 .5rem;
    overflow: hidden;
    background: $light3;
}

.wrap {
    height: 360px;
    width: 100%;
    display: flex;
    z-index: 1;
    align-items: flex-end;

    @media #{$mobile-only} {
        height: 200px;
    }

    @media #{$tablet-portrait-up} {
        height: 260px;
    }

    @media #{$tablet-landscape-up} {
        height: 260px;
    }

    @media #{$laptop-up} {
        height: 360px;
    }

}

.contentContainer {
    z-index: 2;
    margin: 3rem;
    width: 60%;
}

.titleContainer {
    width: 100%
}

.buttonContainer {
    padding-bottom: 1rem;
}

.button {
    background-color: $dark2;
    padding: 0.5rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;

    @media #{$mobile-only} {
        width: 80%;
        margin-bottom: 1rem;
    }

    @media #{$tablet-portrait-up} {
        width: 60%;
        margin-bottom: 1rem;
    }

    @media #{$tablet-landscape-up} {
        width: 50%;
        margin-bottom: 1rem;
    }

    @media #{$laptop-up} {
        width: 30%;
        margin-bottom: 0rem;
    }

    @media #{$desktop-up} {
        width: 30%;
        margin-bottom: 0rem;
    }

}

.buttonText {
    @include primaryP();
    color: $light0;
    font-size: 11px;
    font-weight: 500;
}

.text {
    @include headline();
    color: $light0;

    @media #{$mobile-only} {
        @include primaryH1();
        color: $light0;
    }

    @media #{$tablet-portrait-up} {
        @include primaryH1();
        color: $light0;
    }

    @media #{$tablet-landscape-up} {
        @include primaryH1();
        color: $light0;
    }

    @media #{$laptop-up} {
        @include headline();
        color: $light0;
    }
}