@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    background-color: $light0;
}

.detailContainer {
    padding: 2rem 3rem;
    border-bottom: 1px solid $light3;
}

.headingText {
    @include primaryP();
    color: $dark3;
    font-size: 12px;
    padding-bottom: 1rem;
}

.detailText {
    @include primaryP();
    color: $dark0;
    font-size: 18px;
    font-weight: 500;
}

.descriptionText {
    @include primaryP();
    color: $dark3;
    font-size: 18px;
    font-weight: 500;
    padding-top: 1rem;
}