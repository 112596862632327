@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    background: $panelBg;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2rem 2rem 4rem;
}

.inner {
    max-width: 600px;
    margin: 0 auto;
}

.logo {
    display: block;
    width: 200px;
    height: 200px;
    padding-bottom: 2rem;
    margin: 0 auto;
}

.headingText {
    @include secondaryH1();
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
}

.dateText {
    @include secondaryH3();
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center;
}

.descriptionText {
    @include primaryP();
    text-align: left;
    line-height: 1.8;
}