@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.panel {
    position: relative;
}

.slotContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 3rem 4rem 1rem;
    justify-content: space-between;
}

.wizardText {
    @include primaryP();
    font-size: 1.1rem;
    color: $primary;
    margin-right: 0.5rem;
}

.icon {
    color: $primary;
}

.wizardOpen {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 2rem;
    cursor: pointer;
    position: absolute;
    top: 1.3rem;
    width: 100%;
    right: 1.3rem;
}