@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 80px;
    background: $light0;
    padding: 2rem 1rem;
    box-sizing: border-box;
}

.container:hover {
    cursor: pointer;
}

.container:hover .title {
    color: $primary;
}

.textWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
}

.image {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 36px;
}

.title {
    @include secondaryH4();
}

.content {
    @include secondaryP();
    color: $dark3;
}

.price {
    @include secondaryH4();
    font-weight: bold;
}