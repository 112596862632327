@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
/* Modal */
.modal-mask {
    background: $modalBg;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100000;
    animation-duration: .8s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-name: fadeIn;
}

.modal {
    position: absolute !important;
    background: none;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    z-index: 100000 !important;
    height: auto !important;
}

.modal :global .rodal-mask {
    background: rgba(0, 0, 0, 0);
}

.modal :global .rodal-dialog {
    background: none;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    max-width: 92%;
    height: auto !important;
    bottom: initial !important;
    margin: 0 auto;
    position: relative;
}

:global .modal-top {
    justify-content: start;
}

:global .modal-top .rodal-dialog {
    top: 40px;
}

@if mixin-exists(extendModalModule) {
    @include extendModalModule()
}