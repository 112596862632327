@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.card {
    text-align: center;
    width: 21rem;
    background: $light0;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.image {
    width: 14rem;
    height: 14rem;
    background: $light3;
    border-radius: 100%;
    margin-bottom: 16px;
}

.name {
    @include secondaryH2();
    color: $dark0;
    padding-bottom: 5px;
}

.description {
    @include secondaryH4();
    color: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
}

.stat {
    @include secondaryH3();
    color: #000000;
}

.dot {
    font-size: 10px;
}

.cardmini {
    width: 8rem;
    padding: 1rem;
}

.cardmini :global {
    .image {
        width: 7rem;
        height: 7rem;
    }

    .name {
        @include secondaryH4();
    }
}

.cardsmall :global {
    .image {
        width: 12rem;
        height: 12rem;
    }

    .name {
        font-size: 19px;
    }

    .description {
        font-size: 17px;
    }

    .stat {
        font-size: 19px;
    }
}
