@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.popup {
    position: relative;
    background: $panelBg;
}

.close {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2.2rem;
}

.body {
    padding: 4rem;
}

.text {
    @include primaryP();
    text-align: center;
}

.button {
    border-radius: 0;
    padding: 3rem 1rem;
}