@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.box {
    padding: 2rem;
}

.message {
    text-align: center;
    @include secondaryH3();
    color: $dark4;
}