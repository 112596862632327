@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.head {}

.select {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    color: $light4;
    background: $dark0;
    border: 1px solid $dark0;
}

.body {
    display: block;
}