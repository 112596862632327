@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    background: rgba($blueHl, 0.08);
    padding: 1.5rem 2rem;
}

.text {
    @include secondaryP();
    color: $blueHl;
}

.errorContainer{
    background: rgba($redHl, 0.08);
}

.errorText{
    color: $redHl;
}

.successContainer{
    background: rgba($greenHl, 0.08);
}

.successText{
    color: $greenHl;
}