@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.splash {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: $bodyBg;
}

.splash :global img {
    max-width: 80vw;
    height: auto;
}