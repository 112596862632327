@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.welcomeText {
    @include primaryH1();
    color: $primary;
    text-align: center;
    padding: 1rem;
}

.col {
    background: pink;
}

.avatar {
    width: 100px;
}

.colInner {
    background: yellow;
}

@media #{$mobile-only} {
    //mobile only css
}

@media #{$tablet-portrait-up} {
    //tablet portrait and up
}

@media #{$tablet-landscape-up} {
    //tablet landscape and up
}

@media #{$laptop-up} {
    //laptop and up
}

@media #{$desktop-up} {
    //desktop and up
}