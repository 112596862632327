@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.badge {
    width: 6.6rem;
    height: 6.6rem;
    display: flex;
    border-radius: 5rem;
    background-color: $primary;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: visible;
    margin-right: 5px;
}

.icon {
    width: 3.1rem;
    height: 3.1rem;
    color: $light0;
}

.ownerIcon {
    width: 2.3rem;
    height: 2.3rem;
    position: absolute;
    border-radius: 5rem;
    left: -0.6rem;
    top: 4rem;
    color: $dark1;
}

.videoIcon {
    width: 2.3rem;
    height: 2.3rem;
    position: absolute;
    color: $dark1;
    border-radius: 5rem;
    right: -0.6rem;
    top: 4rem;
    align-items: center;
    justify-content: center;
}

.videoIconAlt {
    @extend .videoIcon;
    color: $alt;
}