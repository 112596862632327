@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.panel {
    background: $light0;
}

.container {
    display: flex;
}

.contentContainer {
    padding-left: 3rem;
    padding-top: 0.5rem;
    font-weight: 600;
    width: 100%;
}

.quickLinksContainer {
    background-color: $dark1;
    width: 100%;
}

.quickLink {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    padding: 2.5rem;
    cursor: pointer;
    // border: 1px solid #50565c;
}

.leftLine {
    width: 1.2rem;
    background-color: #474a4b;
}

.quickLinkWrapper {
    display: flex;
}

.quickLinkWrapper:hover {
    background-color: $primary;

    .leftLine {
        background-color: #e43621;
    }
}

.separator {
    width: 75%;
    border-bottom: 1px solid #50565c;
}

.separatorWrapper {
    display: flex;
    justify-content: center;
}

.LinkContent {
    padding: 0rem 0rem 0rem 2.5rem;
    width: 100%;
}

.titleText {
    @include secondaryH3();
    text-transform: uppercase;
}

.linkTitle {
    @include secondaryH3();
    color: $light3;
}

.linkDescription {
    @include secondaryH4();
    color: $light3;
}

.showMobileViewText {
    display: none;
}

.image {
    object-fit: cover;
    height: auto;
}

.imageContainer {
    width: 100%;
}

.showMobileDescription {
    display: none;
}

// mob views
@media #{$mobile-only} {
    .container {
        flex-direction: column;
    }

    .image {
        width: 100%;
    }

    .titleText {
        font-size: 1.8rem;
        text-align: center;
        font-weight: 600;
    }

    .linkDescription {
        display: none;
    }

    .linkTitle {
        font-size: 1.3rem;
        line-height: 1.8rem;
    }

    .separatorWrapper {
        display: none;
    }

    .contentContainer {
        padding: 0rem;
    }

    .quickLink {
        width: 100%;
        flex-direction: column;
        text-align: center;
    }

    .LinkContent {
        padding: 0;
    }

    .quickLinkWrapper {
        // max-width: 15rem;
    }

    .quickLinksContainer {
        display: flex;
        flex-direction: row;
        flex: 1;
    }

    .mobileViewText {
        display: none;
    }

    .showMobileViewText {
        display: block;
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .link {
        flex: 1;
    }

    .image {
        aspect-ratio: 16/9;
    }
}

@media #{$tablet-portrait-only} {
    .container {
        flex-direction: column;
    }

    .image {
        width: 100%;
    }

    .titleText {
        font-size: 1.8rem;
        text-align: center;
        font-weight: 600;
    }

    .linkDescription {
        display: none;
    }

    .linkTitle {
        font-size: 2rem;
        line-height: 18px;
    }

    .separatorWrapper {
        display: none;
    }

    .contentContainer {
        padding: 0rem;
    }

    .quickLink {
        width: 100%;
        flex-direction: column;
        text-align: center;
    }

    .quickLinkWrapper {
        height: 100%;
    }

    .LinkContent {
        padding: 0;
    }

    .quickLinksContainer {
        display: flex;
        flex-direction: row;
        flex: 1;
    }

    .icon {
        width: 3.75rem;
        height: 3.75rem;
    }

    .link {
        flex: 1;
    }

    .image {
        aspect-ratio: 16/7;
    }
}

@media #{$tablet-landscape-only} {
    .container {
        flex-direction: column;
    }

    .image {
        width: 100%;
    }

    .titleText {
        font-size: 1.8rem;
        text-align: center;
        font-weight: 600;
    }

    .linkDescription {
        display: block;
        font-size: 1.6rem;
        line-height: 2rem;
    }

    .hideDescription {
        display: none;
    }

    .showMobileDescription {
        display: block;
    }

    .linkTitle {
        @include secondaryH4();
        font-size: 2rem;
        line-height: 2.7rem;
        color: $light3;
    }

    .separatorWrapper {
        display: none;
    }

    .contentContainer {
        padding: 0rem;
    }

    .quickLink {
        width: 100%;
        flex-direction: column;
        text-align: center;
        padding: 2rem;
    }

    .quickLinkWrapper {
        height: 100%;
    }

    .LinkContent {
        padding: 0;
    }

    .quickLinksContainer {
        display: flex;
        flex-direction: row;
        flex: 1;
    }

    .icon {
        width: 3.7rem;
        height: 3.7rem;
    }

    .link {
        flex: 1;
    }

    .image {
        aspect-ratio: 16/7;
    }
}

@media #{$laptop-up} {}