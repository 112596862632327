@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {}


.label {
    @include secondaryH4();
    font-size: 15px;
    padding-left: 5px;
    padding-bottom: 5px;
    display: block;
}

.label :global .form-field-label-required {
    color: $redHl;
}

.label :global .form-field-label-required:before {
    content: " ";
}

.input {
    display: block;
    box-sizing: border-box;
    height: 48px;
    width: 100%;
    background: $light2;
    border-radius: 5px;
}

.input :global {

    .form-field-select__control {
        padding: 1rem 1.5rem;
        background: none;
        border: 0 !important;
        box-shadow: none !important;
        min-height: 0;
    }

    .form-field-select__placeholder {
        @include primaryP();
    }

    .form-field-select__single-value {
        @include primaryP();
    }

    .form-field-select__indicator-separator {
        display: none;
    }

    .form-field-select__indicator {
        padding: 4px 0 4px 4px;
        color: inherit;
    }

    .form-field-select__value-container {
        padding: 0;
    }

}

.error {
    @include primaryP();
    color: $redHl;
    font-size: 12px;
}