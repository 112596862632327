@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.relative {
    position: relative;
}

.wrap {
    overflow: hidden;
}

.scroll {
    overflow-y: hidden;
    overflow-x: scroll;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

.scroll::-webkit-scrollbar {
    display: none;
}

.rotate.grid {
    display: flex;
    flex-wrap: nowrap;
    width: max-content;
}

.left {
    display: none;
    cursor: pointer;

    @media #{$laptop-up} {
        display: flex;
        position: absolute;
        top: 50%;
        left: -7rem;
        transform: translateY(-50%);
        background: $light4;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
    }

    @media #{$laptop-only} {
        left: -2rem;
    }
}

.right {
    display: none;
    cursor: pointer;

    @media #{$laptop-up} {
        display: flex;
        position: absolute;
        top: 50%;
        right: -7rem;
        transform: translateY(-50%);
        background: $light4;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
    }

    @media #{$laptop-only} {
        right: -2rem;
    }
}

.left:hover {
    background: $primary;
}

.right:hover {
    background: $primary;
}

.iconRight {
    color: $light1;
    font-size: 30px;
    vertical-align: middle;
    align-self: center;
    margin-left: 0.4rem;
}

.iconLeft {
    color: $light1;
    font-size: 30px;
    vertical-align: middle;
    align-self: center;
    margin-right: 0.4rem;
}

.rotate.columns-2 :global .item {
    width: 700px;
    flex-basis: 700px;
    max-width: 70vw;
}

.rotate.columns-3 :global .item {
    width: 450px;
    flex-basis: 450px;
    max-width: 80vw;
}

.rotate.columns-4 :global .item {
    width: 333px;
    flex-basis: 333px;
}

.rotate.columns-5 :global .item {
    width: 260px;
    flex-basis: 260px;
}

.rotate.columns-6 :global .item {
    width: 215px;
    flex-basis: 215px;
}

.rotate.columns-7 :global .item {
    width: 180px;
    flex-basis: 180px;
}

.rotate.columns-8 :global .item {
    width: 153px;
    flex-basis: 153px;
}

.rotate.columns-9 :global .item {
    width: 136px;
    flex-basis: 136px;
}

.rotate.gap-1 :global .item {
    padding-right: 1rem;
}

.rotate.gap-2 :global .item {
    padding-right: 2rem;
}

.rotate.gap-3 :global .item {
    padding-right: 3rem;
}

.rotate.gap-4 :global .item {
    padding-right: 4rem;
}

.rotate.gap-1 {
    padding-left: 4rem;
}

.rotate.gap-2 {
    padding-left: 4rem;
}

.rotate.gap-3 {
    padding-left: 4rem;
}

.rotate.gap-4 {
    padding-left: 4rem;
}


.fixed {
    flex-wrap: wrap;
    // justify-content: space-around;
    flex-direction: row;
    display: flex;
}

.fixed :global .item {
    box-sizing: border-box;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;

    @media #{$laptop-up} {
        flex-grow: 0;
    }
}

.fixed.columns-1 :global .item {
    width: 100%;
    min-width: 100%;
}

.fixed.columns-2 :global .item {
    width: 50%;
    min-width: 440px;
}

.fixed.columns-3 :global .item {
    width: 33.3333%;
    min-width: 330px;
}

.fixed.columns-4 :global .item {
    width: 25%;
    min-width: 250px;
}

.fixed.columns-5 :global .item {
    width: 20%;
    min-width: 200px;
}

.fixed.columns-6 :global .item {
    width: 16.6667%;
}

.fixed.columns-7 :global .item {
    width: 14.2587%;
}

.fixed.columns-8 :global .item {
    width: 12.5%;
}

.fixed.gap-1 :global .item {
    padding: 1rem;
}

.fixed.gap-2 :global .item {
    padding: 2rem;
}

.fixed.gap-3 :global .item {
    padding: 3rem;
}

.fixed.gap-4 :global .item {
    padding: 4rem;
}

.fixed.gap-1 {
    margin: -1rem;
}

.fixed.gap-2 {
    margin: -2rem;
}

.fixed.gap-3 {
    margin: -1.5rem;
}

.fixed.gap-4 {
    margin: -4rem;
}