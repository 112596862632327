@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    background: $panelBg;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
}

.containerAlt {
    @extend .container;
    background: $light1;
}

.resultsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 65%;
    margin: 0rem 2rem;
}

.teamContainer {
    display: flex;
    flex-wrap: wrap;
}

.teamContainerTest {
    display: flex;
    flex-direction: row;
}

.divider {
    background-color: $light3;
    width: 1px;
    margin: 1rem 0rem;

    @media #{$mobile-only} {
        margin: 2rem 0rem;
    }
}

.dateContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0rem 0.5rem;
    text-align: center;
}

.imageContainer {
    display: flex;
    padding: 1rem 2rem;
}

.textContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.teamNameContainer {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    float: left;
}

.scoreContainer {
    display: flex;
    align-items: center;
    margin-right: auto;
    flex-direction: row;
}

.teamImage {
    width: 30px;
    height: 30px;
    border-radius: 15px;
}

.teamText {
    @include primaryP();
    color: $dark1;
    font-weight: 500;
    font-size: 16px;

    @media #{$mobile-only} {
        font-size: 12px;
    }
}

.scoreText {
    @include primaryP();
    color: $dark1;
    font-weight: 700;
}

.dateText {
    @include primaryP();
    color: $secondary;
    font-weight: 500;
    font-size: 12px;

    @media #{$mobile-only} {
        font-size: 10px;
    }
}

.finalText {
    @include primaryP();
    color: $dark1;
    font-weight: 800;
    line-height: 30px;
}