@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
@media #{$mobile-only},
#{$tablet-portrait-only},
#{$tablet-landscape-only} {
    .container {
        z-index: 999999;
        background-color: $light1;
        border-radius: 15px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        margin: 0.2rem 5.5rem 0 2rem;

        @media #{$tablet-portrait-only},
        #{$tablet-landscape-only} {
            margin-top: 100px;
            margin-right: 0;
        }

        @media #{$tablet-portrait-up} {
            position: absolute;
            top: -30px;
            right: 8px;
        }
    }

    .contentContainer {
        padding: 0.8rem 0.5rem 0.2rem 0.5rem;
    }

    .accountContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0rem 1rem 1rem 0rem;
    }

    .userImage {
        display: flex;
        width: 32px;
        height: 32px;
        margin-right: 7.95px;
        border-radius: 50%;
    }

    .listContainer {
        display: flex;
        margin-bottom: 0.8rem;
        padding: 1rem 1rem;
        // background-color: $light2;
        border-radius: 15px;
    }

    .link {
        @include primaryP();
        color: $dark1;
        font-weight: 500;
        text-align: center;
    }

}

@media #{$laptop-up} {
    .container {
        z-index: 2;
        position: absolute;
        top: -30px;
        right: 8px;
        margin-top: 100px;
        cursor: pointer;
    }

    .contentContainer {
        background-color: $light1;
        border-radius: 4%;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        padding: 0.8rem 0.5rem 0.2rem 0.5rem;
    }

    .accountContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0rem 2rem 1rem 2rem;
    }

    .userImage {
        display: flex;
        width: 32px;
        height: 32px;
        margin-right: 8px;
        border-radius: 50%;
    }

    .listContainer {
        display: flex;
        margin-bottom: 0.8rem;
        padding: 1rem 1rem;
    }

    .listContainer:hover {
        display: flex;
        margin-bottom: 0.8rem;
        padding: 1rem 1rem;
        background-color: $light2;
        border-radius: 10%;
    }

    .listContainer:hover .link {
        color: $primary;
    }

    .link {
        @include primaryP();
        color: $dark1;
        font-weight: 500;
        text-align: center;
    }

    .link:hover {
        color: $primary;
    }
}