@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.headingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem 4rem;
    background: $blueHl;
    text-align: center;
}

.headingButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 2rem;
}

.headingButtons a {
    margin: 0 1rem;
}

.headingContainer:global.paid {
    background: $greenHl;
}

.headingContainer:global.due,
.headingContainer:global.failed {
    background: $redHl;
}

.headingTitle {
    @include primaryP();
    color: $light0;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 1rem;
}

.headingMessage {
    @include primaryP();
    color: $light0;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
}

.hostBackIcon {
    font-size: .75em;
    position: relative;
}

.hostContainer {
    text-align: center;
    padding: 2rem 2rem;
    background: $light0;
    display: block;
}

.hostLogo {
    width: 100%;
    max-width: 120px;
    height: auto;
    display: block;
    margin: auto;
}

.hostName {
    @include secondaryH2();
}