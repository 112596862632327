@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countContainer {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 200px;
    min-height: 200px;
    border: 4px solid $primary;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.count {
    @include primaryP();
    font-size: 80px;
    color: $primary;
}

.refCode {
    @include primaryH1();
    text-align: center;
    font-weight: 300;
    letter-spacing: 5px;
    padding-left: 0;
    padding-right: 0;
}