@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.subHeading {
    @include secondaryH4();
    font-weight: 600;
    margin-bottom: 1rem;
}

.btnIcon {
    color: $primary !important;
}

.buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

.icon {
    color: rgba($dark0, 0.54);
    margin-right: 0.5rem;
}

.flex {
    display: flex;
    margin-bottom: 1rem;
}