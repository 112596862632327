@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    background-color: $light0;
}

.methods {
    padding: 4rem;
}

.method {
    cursor: pointer;
    background-color: $dark1;
    display: flex;
    align-items: center;
    height: 60px;
    border: 1px solid $dark0;
}

.text {
    display: flex;
    flex-direction: row;
    padding: 0 2rem;
    flex: 2;
    justify-content: space-between;
    align-items: center;
}

.icon {
    height: 70px;
    height: 60px;
}

.label {
    @include primaryP();
    color: $light0;
    font-weight: 600;
}

.desc {
    @include primaryP();
    color: $light0;
    font-weight: 400;
    opacity: .6;
}

.arrow {
    font-size: 2rem;
    color: $light0;
}

.indicator {
    text-align: center;
}

.indicator :global .css-0 div {
    background-color: $primary;
}