@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {
    background-color: $panelBg;
}

.galleryWrap {
    background-color: $bodyBg;
    overflow: hidden;
}

.title {
    @include primaryH1();
    line-height: 50px;
    font-size: 42px;
    font-weight: 600;
    color: $dark1;
}

.description {
    @include primaryH2();
    font-weight: 300;
    line-height: 1.5;
    color: $dark3;
}

.meta {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.category {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.categoryImage {
    width: 24px;
    height: 24px;
    margin-right: 0.4rem;
    border-radius: 50%;
}

.categoryTitle {
    @include primaryP();
    font-size: 12px;
    color: $dark1;
}

.date {
    @include primaryP();
    color: $secondary;
    font-size: 12px;
}

.image {
    max-width: 100%;
    height: auto;
}


.back {
    display: flex;
    flex-direction: row;
    margin-left: 18rem;
    z-index: 2;
    position: absolute;
}

.backText {
    @include secondaryH1();
    font-size: 24px;
    line-height: 20px;
    font-weight: 400;
    margin-right: 0.4rem;
    cursor: pointer;
}