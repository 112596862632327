@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.bannerWrapper {
    width: 100%;
    height: 370px;
    background-color: $secondary;
}
.container {
    position: relative;
    height: 370px;
    display: flex;
    align-items: center;
    padding: 2.8rem;
    box-sizing: border-box;
}