@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding: 4rem 0;
}

.field {
    @include primaryP();
    display: block;
    box-sizing: border-box;
    padding: 1rem 1.5rem;
    height: 48px;
    width: 100%;
    background: $light2;
    border-radius: 5px;
}

.label {
    @include secondaryH4();
    font-size: 14px;
    padding-left: 5px;
    padding-bottom: 5px;
    display: block;
}

.label :global .form-field-label-required {
    color: $redHl;
}

.label :global .form-field-label-required:before {
    content: " ";
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}