@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.details {
    display: flex;
}

.avatar {
    margin-right: 2rem;
    width: 6rem;
    height: 6rem;
}

.title {
    @include secondaryH3();
    font-weight: 500;
    font-size: 2.2rem;
}

.hostName {
    @include secondaryP();
    color: $dark3;
}

.date {
    @include secondaryP();
    color: $dark3;
}

.lineWrapper {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.content {
    @include secondaryP();
    color: $dark0;
    font-weight: 400;
}

.tabBubbleWrap{
    display: flex;
    margin-top: 2rem;
    gap: .5rem;
}