@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.btn {
    display: inline-flex;
    text-align: center;
    padding: 0 3rem;
    height: 48px;
    border-radius: 5px;
    box-sizing: border-box;
    @include primaryH4();
    line-height: 48px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s ease all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    outline: none;
    background-color: $primary;
    color: $light0;
    justify-content: center;
    align-items: center;
}

.block {
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.indicator {
    position: relative;
    top: 2px;
}

.indicator :global .css-0 div {
    background-color: $light0;
}

.label {}

.round {
    border-radius: 48px;
}

.small {
    font-size: 1.4rem;
    height: 3.6rem;
    line-height: 3.6rem;
    padding: 0 1rem;
    font-weight: 400
}

.medium {
    font-size: 1.4rem;
    height: 4rem;
    line-height: 4rem;
    padding: 0 1.5rem;
    font-weight: 400
}

.large {}

.btn :global .icon {
    font-size: 2rem;
}

.medium :global .icon {
    font-size: 2rem;
}

.large :global .icon {
    font-size: 2.4rem;
}

.iconBefore {
    margin-right: .8rem;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.iconAfter {
    margin-left: .8rem;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.primary {
    background-color: $primary;
    color: $light0;
}

.secondary {
    background-color: $alt;
    color: $dark1;

    @media #{$mobile-only} {

        font-size: 1.2rem;

    }
}

.alt {
    background-color: $secondary;
    color: $light0;
}

.faded {
    background-color: $light2;
    color: $dark3;
}

.success {
    background-color: $greenHl;
    color: $light0;
}

.btn:hover {
    background-color: $dark1;
    color: $light0;
}