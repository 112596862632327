@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.messageContainer {
    overflow-y: auto;
    height: 444px;
    display: flex;
    flex-direction: column-reverse;
    background-color: $light2;
}

.chatBubble {
    margin: 10px 10px 0;
    display: flex;
    flex-direction: column;
}

.chatBubbleSender {
    align-items: flex-start;

}

.inner {
    display: flex;
    // align-items: center;
}

.innerSender {
    justify-content: flex-end;
}

.chatBubbleText {
    @include primaryP();
    background-color: $light1;
    padding: 0 10px 10px;
    // margin-left: 10px;
    font-family: $fontTertiary;
    // color: $dark1;
    // border-radius: 5px;
}

.chatBubbleTextSender {
    background-color: transparent;
    margin-right: 10px;
    margin-left: 0;
    font-size: 12px;
    // font-family: $fontTertiary;
    color: $dark1;
}

.profileName {
    color: $dark0;
    font-weight: 500;
    // font-family: $fontTertiary;
    font-size: 12px;
    float: left;
    margin-right: 10px;
}

.profileImage {
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

// .time {
//     @include primaryP();
//     color: $dark3;
//     font-size: 10px;
// }

.link {
    color: inherit;
    text-decoration: underline;
}

.linkSender {
    color: inherit;
    text-decoration: underline;
}