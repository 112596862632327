@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.box {
    background: $panelBg;
}

.popup {
    position: relative;
    background: $panelBg;
}

.close {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 2.2rem;
}

.headingContainer {
    padding: 1rem 0 2rem 0;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0 2rem 0;
}

.headerAlt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0 4rem 0;
}

.sessionCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 4rem 4rem 4rem;
}

.footerContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.footerContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 1.2rem;
}

.buttonContainer {
    padding-bottom: 4rem;
    width: 35rem;
}

.colContainer {
    padding-bottom: 2rem;
}

.addAthleteLink {
    padding: 2rem 0 0;
    text-align: center;
    display: block;
    @include secondaryH4();
    color: $primary;
    cursor: pointer;
}

.imageCol {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.photoHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
}

.image {
    width: 160px;
    height: 160px;
    border-radius: 100%;
    display: flex;

}

.iconContainer {
    margin-top: -3.8rem;
    margin-left: 120px;
    z-index: 2;
}

.icon {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    position: relative;
    bottom: 0;
    right: 0;
    z-index: 2;
}

.text {
    @include primaryP();
    color: $dark3;
}

.profileText {
    @include secondaryH4();
}

.headerText {
    @include primaryP();
    color: $dark3;
    padding-bottom: 1rem;
    text-align: center;
}

.headerTextAlt {
    @include primaryP();
    color: $dark3;
    padding-bottom: 1rem;
    text-align: center;
}

.nameText {
    @include primaryP();
    font-size: 20px;
    text-align: center;
}