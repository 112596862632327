@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.footer {
    position: relative;
    box-sizing: border-box;
    background: #FF4E39;
    width: 100%;
    padding: 7rem 0;
}

.logo {
    width: 200px;
    margin: 0 auto 4rem;
    padding: 1rem;
    display: block;
}


.customFlex {
    justify-content: space-between;
}

.copyright {
    @include primaryP();
    font-weight: 500;
    color: $dark0;
    padding: 1.5rem 0;
    text-align: center;
}

.menu a {
    text-transform: uppercase;
    font-style: normal;
    text-align: center;
    @include primaryP();
    font-weight: 500;
    color: $light0;
    padding: 1.5rem;
    display: block;

}

@media #{$tablet-landscape-up} {


    .logo {
        margin: 0 0 3rem;
    }

    .copyright {
        text-align: right;
        font-size: 17px;
    }

    .menu {
        margin-left: -1.5rem;
    }

    .menu a {
        display: inline-block;
        font-size: 17px;
    }

}