@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.featuredStats {
    display: flex;
    flex-direction: row;
    background-color: $light0;
    width: 100%;
    height: 120px;
    justify-content: space-between;
    align-items: center;
}

.leftContainer {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;

    @media #{$mobile-only} {
        width: 30%;
    }

    .titleContainer {
        padding: 0 2rem;
    }

    .titleText {
        @include primaryH2();
        color: $dark1;
        padding-right: 1rem;
    }

}

.scrollContainer {
    display: flex;
    height: 120px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    padding-right: 2rem;
}

.itemsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 120px;
    flex: 1;

}

.itemContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem;
    text-align: center;
}

.nameText {
    width: 50px;
    @include primaryH4();
    color: $dark3;
    padding-bottom: 1rem;
    text-align: center;
}

.valueText {
    @include primaryH2();
    color: $dark1;

    @media #{$tablet-portrait-up} {
        width: 60px;
    }

    @media #{$mobile-only} {
        width: 60px;
    }
}

@media #{$mobile-only} {
    .itemsContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 120px;
    }
}

@media #{$tablet-portrait-up} {
    .itemsContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 120px;
    }
}

@media #{$tablet-landscape-up} {

    .scrollContainer {
        display: flex;
        height: 120px;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        align-self: center;
        padding-right: 2rem;
    }

    .itemsContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        height: 120px;
    }
}

@media #{$laptop-up} {
    .itemsContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
}

@media #{$desktop-up} {
    .itemsContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
}