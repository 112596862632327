@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    width: 100%;
    height: 18rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    @include secondaryH3();
    color: $light0;
    font-weight: 500;
}

.count {
    @include secondaryP();
    color: $light0;
    font-size: 1.3rem;
}