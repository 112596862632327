@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    width: 100%;
    margin-bottom: 1rem;
    overflow: hidden;
}

.imageWrap {
    &:hover .overlay {
        opacity: 1;
    }

    &:hover .title {
        color: $primary;
    }
}

.imageContainer {
    position: relative;
    margin-bottom: 1rem;
    border-radius: 8px;
    overflow: hidden;
}

.overlay {
    position: absolute;
    background-color: rgba($primary, 0.25);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    transition: all .3s ease;
}

.image {
    width: 100%;
    height: auto;
    background: $light3;
}

.live {
    @include secondaryP();
    color: $light0;
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    background-color: $primary;
    padding: .2rem .5rem;
}

.contentContainer {
    flex-direction: column;
    margin-top: .5rem;
}

.contentInner {
    flex-direction: row;
    display: flex;
    align-items: center;
    min-height: 2.5rem;
}

.avatar {
    margin-right: .5rem;
    width: 2.5rem;
    height: 2.5rem;
}

.title {
    @include secondaryH3();
    transition: .3s ease all;
    margin-bottom: .5rem;
    max-height: 64px;
    overflow: hidden;
}

.meta {
    @include secondaryH5();
    color: $dark3;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.hostName {
    @include secondaryH5();
    padding-right: .5rem;

    &:hover {
        color: $primary
    }
}