@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.banner {
    width: 100%;
    height: 240px;
    background-color: $secondary;
}

.center {
    position: relative;
    height: 240px;
    display: flex;
    align-items: center;
    padding: 28px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: rgba($dark1, 0.4);
}

.avatar {
    width: 172px;
    height: 172px;
    background: #dfe4ea;
    border-radius: 100%;
    margin-right: 20px;
    color: $light0;
    z-index: 1;
    overflow: hidden;

    @media #{$mobile-only} {
        width: 140px;
        height: 140px;
    }
}

.headings {
    display: block;
    z-index: 1;
}

.heading {
    @include primaryH1();
    color: $light0;
}

.subHeading {
    @include secondaryH3();
    color: $light0;
}

.badge {
    padding: 1rem;
    background-color: $primary;
    border-radius: 50px;
    text-align: center;
    margin: 1rem 0;
    @include primaryH4();
    color: $light0;
}

.point {
    padding: 1rem 1.5rem;
    background-color: $primary;
    border-radius: 50px;
    text-align: center;
    margin: 1rem 0;
    @include primaryH2();
    color: $light0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$mobile-only} {
        padding: 0.5rem;
        margin: 0.5rem 0;
    }
}

.smallText {
    @include primaryP();
    color: $light0;
    font-size: 12px;
    padding-left: 1rem;
}

.btnCorner {
    background-color: $dark2;
    padding: 1rem 2.5rem;
    z-index: 1;
    border-radius: 40px;
    bottom: 0;
    right: 3rem;
    position: absolute;
    margin: 1.5rem 3rem;

    // text
    @include primaryP();
    font-size: 12px;
    color: $light0;

    @media #{$mobile-only} {
        margin: 0 auto;
        margin: 1.5rem 3rem;
        padding: 1rem 2rem;
    }
}

//Banner Editor
.profileImage {
    width: 172px;
    height: 172px;
    margin-right: 20px;
}

.formImageCover {
    position: absolute;
    right: 0;
    bottom: 20px;
    // width: 160px;
}

.formImageCover :global .image {
    opacity: 0;
}