@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.outerContainer {
    border: 1px solid $light3;
}

.mainContainer {
    padding: 8px;
    background: $light1 ;
}

.chatContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 20rem;
    max-height: 50rem;
}