@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    padding: 2rem 4rem;
    background-color: $light1;
    border-top: 1px solid $light3;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.labelSmall {
    @include secondaryP();
    font-size: 1.2rem;
    color: $dark3;
    font-weight: 400;
}

.labelLarge {
    @include secondaryH3();
    margin-top: .25rem;
    font-size: 2rem;
    font-weight: 500;

    @media #{$mobile-only} {
        font-size: 1.6rem;
    }
}

.icon {
    margin-left: 0.5rem;
    padding-top: 0.2rem;
}

.textContainer {
    padding: 0 1rem 1rem 1rem;
}