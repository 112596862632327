@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    background-color: $light0;
}

.form {}

.sectionHeading {
    @include primaryH2();
    margin-top: 1rem;
}

.sectionText {
    @include primaryP();
    margin-top: .5rem;
}

.teamsGrid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 1rem;
    padding: 1rem;
}

.gridItem{
    display: flex;
    align-items: center;
    justify-content: center;
}