@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
	display: flex;
	align-items: center;
}

.item  {
}

.itemLeader {
	padding-right: 2rem;
	margin-right: 2rem;
	border-right: 4px solid $light2;
}

.select {
	width: 200px;
}